import {
    createRouter,
    createWebHashHistory
} from 'vue-router'

import store
    from "@/store";

import NProgress
    from "nprogress";
import HomeView
    from '../views/HomeView.vue'
import WorkPlaces
    from "../views/WorkPlaces.vue";
import MeetingSpaces
    from "../views/MeetingSpaces.vue";
import ExtraProducts
    from '../views/ExtraProducts.vue'
import BookingConditions
    from "@/views/BookingConditions";
import ErrorDisplay
    from "@/views/ErrorDisplay.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/workplaces',
        name: 'workplaces',
        component: WorkPlaces,
        props: true
    },
    {
        path: '/meetingspaces',
        name: 'meetingspaces',
        component: MeetingSpaces,
        props: true
    },
    {
        path: '/extraproducts',
        name: 'extraproducts',
        component: ExtraProducts
    },
    {
        path: '/bookingconditions',
        name: 'bookingconditions',
        component: BookingConditions
    },
    {
        path: '/workplaces:afterWorkplaces(.*)*',
        name: 'workplaces',
        component: WorkPlaces,
        props: true
    },
    {
        path: '/meetingspaces:afterMeetingspaces(.*)*',
        name: 'meetingspaces',
        component: MeetingSpaces
    },
    {
        path: '/extraproducts:afterExtraproducts(.*)*',
        name: 'extraproducts',
        component: ExtraProducts
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'ErrorDisplay',
        props: true,
        component: ErrorDisplay
    },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeResolve((to, from, next) => {
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }


    store.dispatch('displayCart', false)

    if(to.hash !== from.hash) {
        if(to.fullPath == '/workplaces') {
            store.dispatch('updateWorkplacesearch',[ '', 'workplaces'])
            WorkPlaces.workplacesearch = ''
        }
        if(to.fullPath == '/meetingspaces') {
            store.dispatch('updateMeetingspacesearch', ['', 'meetingspaces'])
        }
    }
    next()
})
router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
    store.dispatch('setCurrentroute', from.fullPath)

    // Check for the mobile-menu to be open on route. If so, close it
    let el = document.getElementById('disclosurebutton');
    let isOpen = el.getAttribute('aria-expanded');
    if(isOpen == 'true') {
        el.click();
    }
})

export default router
