/* eslint-disable */
import {createI18n } from 'vue-i18n'

// Translation objects are loaded in index.html
// import EN from '../assets/translations/en.js'
// import NL from '../assets/translations/nl.js'

const i18n = createI18n({
    locale: 'EN', // set default language
    messages:{
        EN,
        NL
    }
})

export default i18n
