import axios
    from 'axios'
import NProgress
    from "nprogress";

var cUrl = 'https://www.loadsplanner.nl/test';

if (location.host.indexOf('localhost') > -1) {
    cUrl = 'http://localhost:8888/loadsplanner/';
}

if (location.host.indexOf('loadsbooker-dev') > -1) {
    cUrl = 'https://loadsplanner-dev.groove-it.it';
}

if (
    location.host.indexOf('www.loadsplanner.nl') > -1 ||
    location.host.indexOf('loadsplanner.nl') > -1 ||
    location.host.indexOf('booker.loadsplanner.nl') > -1
) {
    cUrl = 'https://www.admin.loadsplanner.nl/';
}

if (location.host.indexOf('loadsbooker.loadsplanner.nl') > -1) {
    cUrl = 'https://test.loadsplanner.nl/';
}


const apiClient = axios.create({
    baseURL: cUrl,
    withCredentials: false,
    auth: {
        username: 'loadsbooker',
        password: 'xud*wzf2hrd7DBP-pwr'
    },
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

// before a request is made start the nprogress
apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})

// before a response is returned stop nprogress
apiClient.interceptors.response.use(response => {
    NProgress.done()
    return response
})


export default {
    getSpaces(cType, iMonthfrom, iMonthto, cSearch) {
        const cPath = 'json/loadsbooker?filters={"monthfrom": ' + iMonthfrom + ', "monthto" : ' + iMonthto
            + (cSearch !== undefined && cSearch !== '' ? ', "cSpace":"' + cSearch + '"' : '')
            + (cType !== undefined && cType !== '' ? ', "cType":"' + cType + '"' : '')
            + '}'
        return apiClient.get(cPath)
    },
    getHome() {
        const cPath = 'json/loadsbooker?filters={"cType":"home"}'
        return apiClient.get(cPath)
    },
    checkEmail(cEmail) {
        const cPath = 'checkemail.php?emailaddress=' + cEmail
        return apiClient.get(cPath)
    },
    postBooking(oBooking) {

        return apiClient.post(
            'json/loadsbooker', {
                'data': oBooking
            })
    },
    getTranslations() {
        const cPath = 'json/sw_translations?filters='
        return apiClient.get(cPath)
    }
}
