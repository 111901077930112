<template>
  <div id="app" class="grid place-items-center overflow-y-hidden">
    <div id="nav" class="z-50 w-full sticky top-0" :class="{'hidden' : this.$store.state.displaytype == 'clean'}">
      <nav>
        <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
          <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div class="relative flex h-16 items-center justify-between">
              <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <!-- Mobile menu button-->
                <DisclosureButton id="disclosurebutton" class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span class="sr-only">Open main menu</span>
                  <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true"/>
                  <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true"/>
                </DisclosureButton>
              </div>
              <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
<!--                <div class="flex flex-shrink-0 items-center">-->
<!--                  <img id="loadslogo" class="block h-9 w-auto lg:hidden" src="img/loadsplanner_logo.jpg" alt="Loads.work"/>-->
<!--                  <img id="loadslogo" class="hidden h-9 lg:block" src="img/loadsplanner_logo.jpg" alt="Loads.work"/>-->
<!--                </div>-->
                <div class="hidden sm:block">
                  <div class="flex space-x-4 ">
                    <router-link v-for="item in menuItems" :key="item.loc" :to="item.to"
                                 class="flex flex-nowrap"
                                 :class="[
                        $route.name === item.loc ?
                  'bg-gray-700 text-white' :
                  'text-gray-300 hover:bg-gray-700 hover:text-white flex-nowrap',
                  'px-3 py-2 rounded-md text-sm font-medium flex-nowrap'
                ]" :aria-current="$route.name === item.loc ? 'page' : undefined">
                      <div class="">
                        <component :is="item.icon" fill="currentColor" class="h-6 w-6 inline-block" aria-hidden="true"/>
                      </div>
                      <div class=" ml-3 text-lg">
                        {{ $t(item.title) }}
                      </div>
                    </router-link>
                    <div class = "text-red-500 text-2xl mt-2">{{ environment }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DisclosurePanel class="sm:hidden">
            <div class="space-y-1 px-2 pt-2 pb-3 text-left">
              <DisclosureButton v-for="item in menuItems" :key="item.loc" as="a"
                                :class="[$route.name === item.loc ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']"
                                :aria-current="$route.name === item.loc ? 'page' : undefined">
                <router-link :to="item.to" class="text-white">
                  <component :is="item.icon" fill="currentColor" class="h-6 w-6 inline-block" aria-hidden="true"/>
                  <span class="relative top-1 ml-1">{{ $t(item.title) }}</span>
                </router-link>
              </DisclosureButton>              
            </div>            
          </DisclosurePanel>
        </Disclosure>
        <div class="sm:hidden text-red-500 text-2xl mt-2" style="position: absolute;top: 10px;left: 114px;">{{ environment }}</div>
      </nav>
    </div>

     
    <div class="w-full  max-w-screen-xl"
      :class="{'bg-slate-100' : this.$store.state.displaytype !== 'clean'}"
    >
      <router-view class="float-left w-full lg:w-3/5"/>
      <my-reservations v-if="this.$store.state.displaytype !== 'clean'"></my-reservations>
    </div>
  </div>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue';

import {
  Bars3Icon,
  BellIcon,
  HomeIcon,
  XMarkIcon,
  ComputerDesktopIcon,
  UserGroupIcon,
  CubeTransparentIcon,
  UserPlusIcon

} from '@heroicons/vue/24/solid';
import MyReservations
  from "@/views/MyReservations";

export default {
  components: {
    MyReservations,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    BellIcon,
    HomeIcon,
    XMarkIcon,
    ComputerDesktopIcon,
    UserGroupIcon,
    CubeTransparentIcon,
    UserPlusIcon
  },
  data() {
    return {
      menuItems: [
        {
          loc: "home",
          title: "HOME",
          icon: HomeIcon,
          to: {name: 'home'}
        },
        {
          loc: "meetingspaces",
          title: "MEETINGSPACES",
          icon: UserGroupIcon,
          to: {name: 'meetingspaces'}
        },
        {
          loc: "workplaces",
          title: "WORKPLACES",
          icon: ComputerDesktopIcon,
          to: {name: 'workplaces'}
        },
        {
          loc: "extraproducts",
          title: "EXTRA",
          icon: UserPlusIcon,
          to: {name: 'extraproducts'}
        }
      ],
      languages: [
        {
          name: 'Nederlands',
          href: '#',
          short: 'NL',
          code: 'nl',
          current: true
        },
        {
          name: 'English',
          href: '#',
          short: 'EN',
          code: 'en',
          current: false
        }
      ],
      environment: this.$store.state.environment
    }
  },
  computed: {
    displaytype() {
      return this.$store.state.displaytype
    },
  },
  methods: {
    selectLanguage(languageItem) {
      this.languages.filter((t) => (t.current = (t == languageItem)));
      // this.$store.dispatch('setLanguage', languageItem.code)
      this.$i18n.locale = languageItem.short
    },
    getTranslation(translationcode) {
      const translation = this.$store.state.translations.find((oTranslation) => {
        return oTranslation.cLanguagecode == this.$store.state.language && oTranslation.cTranslationcode == translationcode
      })
      return (translation ? translation.cTranslation : translationcode)
    }
  },
  beforeCreate() {
    let cEnvironment = '';
    if(location.href.indexOf('loadsbooker') > -1) {
      // Set environment in frontend
      cEnvironment = 'test-DO';
    }
    if(location.href.indexOf('loadsbooker-dev') > -1) {
      // Set environment in frontend
      cEnvironment = 'dev-DO';
    }
    this.$store.dispatch('setEnvironment', cEnvironment);
    if(cEnvironment !== '') {
       document.title = '[' + cEnvironment + '] ' + document.title;
    }
  },
  created() {
    this.$store.dispatch('setLoadsplannerUrl')
    this.$store.dispatch('setCartid')
    let email = $cookies.get('emailaddress'); // eslint-disable-line no-undef
    if(email !== null) {
      this.$store.dispatch('setEmail', email)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav a {
  font-weight: bold;
  color: #2c3e50;
}

</style>
