<template>
  <div class="home bg-slate-100">
    <div class="">
      <div class="w-full">
        <div class="w-full p-0 sm:px-7 sm:py-4 sm:pb-2 border border-gray-200 bg-white">
          <h1 class="text-lg md:text-4xl mt-1 ml-3 sm:mt-0 sm:ml-0 sm:pt-2 sm:pb-4">{{ $t('NOTFOUND') }}</h1>
        </div>
        <div class="overflow-y-scroll max-h-screen pb-80 sm:pt-0">
          <div class="w-full ">
            <div class="p-0 sm:p-2 bg-slate-100">
              <div class="intro m-2 p-5 bg-white border border-gray-200 rounded-md">
                <h2 class="text-left">{{ $t('NOTFOUND_TITLE') }}</h2>
                <p class="text-left"  v-html="$t('NOTFOUND_BODY')"></p>
              </div>
            </div>
          </div>
          <div class=" md:w-1/2 float-left">
            <div class="p-0 sm:p-2 bg-slate-100">
              <div class="intro workspaces m-2 p-5 bg-white border border-gray-200 rounded-md">
                <router-link :to="{ name : 'workplaces'}"
                             class="button justify-center m-auto inline-flex w-full mt-5 items-center rounded-md border border-transparent bg-black  px-4 py-2  text-lg font-bold font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  <ComputerDesktopIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
                  {{ $t('GOTO') }} {{ $t('WORKPLACES') }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="w-full sm:w-1/2 float-left">
            <div class="sm:p-2 bg-slate-100">
              <div class="intro  m-2 p-5 bg-white border border-gray-200  rounded-md">
                <router-link :to="{ name : 'meetingspaces'}"
                             class="button justify-center m-auto inline-flex w-full mt-5 items-center rounded-md border border-transparent bg-black  px-4 py-2  text-lg font-bold font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  <UserGroupIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
                  {{ $t('GOTO') }} {{ $t('MEETINGSPACES') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  ComputerDesktopIcon,
  UserGroupIcon,
  // CubeTransparentIcon

} from '@heroicons/vue/24/solid';

export default {
  name: 'HomeView',
  components: {
    ComputerDesktopIcon,
    UserGroupIcon,
    // CubeTransparentIcon
  },
  mounted() {
    this.$store.dispatch('getHome', 'home')
  }
}
</script>

<style scoped>
.contact a,
.contact a:link,
.contact a:visited,
.contact a:active {
  text-decoration: underline !important;
}

</style>
