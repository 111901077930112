<template>
  <div class="meetingspaces w-full lg:block"
       :class="{
          'hidden' : displayCarton,
          'bg-slate-100' : this.$store.state.displaytype !== 'clean'
      }"
  >
    <div class="w-full ">
      <div class="w-full">
        <div
            class="z-50 grid grid-cols-3 p-0 sm:px-7 sm:py-4 sm:pt-5 sm:pb-1  border border-gray-200 bg-white sticky top-16"
            :class="{'hidden' : this.$store.state.displaytype == 'clean'}"
        >
          <div>
            <h1 class="text-2xl sm:text-4xl mt-3 ml-3 sm:mt-1 sm:ml-0">{{ $t('MEETINGSPACES') }}</h1>
          </div>
          <div class="text-left col-span-3 md:col-span-2 pl-2 pb-4 md:text-right md:pr-3">
            <label for="meetingspacesearch" class="sr-only">Meetingspacesearch</label>
            <div class="mt-1">
              <input type="text" name="meetingspacesearch" id="meetingspacesearch" v-model="meetingspacesearch"
                     class="border-gray-200 w-4/5 border rounded-md h-9 p-2 focus:outline-none"
                     @keydown.enter="updateMeetingspacesearch()"
                     :placeholder="$t('SEARCHFORGENERAL')"
              />
              <button type="button"
                      v-if="meetingspacesearch !== ''"
                      :title="$t('CLEARSELECTION')"
                      class="text-gray-500 hover:text-gray-800 absolute right-16 bottom-5 sm:bottom-6 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      @click="clearMeetingspacesearch()"
              >
                <XMarkIcon class="h-5 w-5 text-grey-400"></XMarkIcon>
              </button>
              <button type="button"
                      class="inline-flex items-center rounded-md border border-gray-300 bg-white mt-2 px-2 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                      @click="updateMeetingspacesearch()"
              >
                <span v-if="!isLoading">
                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"></path>
                    </svg>
                </span>
                <span v-if="isLoading" class="inline-flex">
                    <svg class="animate-spin h-4 w-4 blue" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="silver" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </span>
              </button>
              <!--              <button type="button" class="inline-flex items-center rounded-md border border-gray-300 bg-white mt-2 px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"-->
              <!--                      v-if="meetingspacesearch !== ''"-->
              <!--                      @click="clearMeetingspacesearch()"-->
              <!--              >-->
              <!--                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
              <!--                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
              <!--                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>-->
              <!--                  </svg>-->
              <!--                </svg>-->
              <!--              </button>-->
              <button type="button"
                      class="lg:hidden fixed bottom-10 right-10 w-20 h-20 inline-flex justify-center items-center rounded-full border-2 border-white bg-green-500 hover:bg-green-500 mt-2 px-3 py-2 text-sm font-medium leading-4 text-white  hover:bg-gray-50 focus:outline-none"
                      :class="{'text-white' : reservationids.length > 0  || bookedextraproducts > 0}"
                      v-if="reservationids.length > 0 || bookedextraproducts > 0"
                      @click="displayCart()"
              >
                <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                </svg>
                <div v-if="reservationids.length > 0 || bookedextraproducts > 0" id="cartcount"
                     class="align-middle w-8 h-6 rounded-full bg-red-500 fixed bottom-20 right-10 font-bold pt-1.5">
                  {{ reservationids.length + bookedextraproducts }}
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="overflow-y-scroll max-h-screen pb-44" v-on:scroll="handleScroll">
          <div class="w-full">
            <div class="p-0 sm:p-2 "
            :class="{'bg-slate-100' : this.$store.state.displaytype !== 'clean'}">
              <div id="meetingspaces" class="intro m-0.5 sm:m-2 p-1 lg:p-2 bg-transparent">
                <div v-if="meetingspaces.length == 0">
                  <div class="rounded-md bg-yellow-50 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true"/>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-lg font-medium text-yellow-800 text-left">
                          <span v-if="isLoading">{{ $t('LOADING') }}</span>
                          <span v-if="!isLoading">{{ $t('NOSPACESFOUND') }}</span>
                        </h3>
                        <div class="mt-2 text-sm text-yellow-700">
                          <p v-if="isLoading" class="blinking">{{ $t('LOADING_EXPLANATION') }}</p>
                          <p v-if="!isLoading">{{ $t('NOSPACESFOUND_EXPLANATION') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="meetingspace in meetingspaces" :key="meetingspace.spacedata.id" :meetingspace="meetingspace"
                     class="w-full p-1 lg:p-y8 mb-3 border border-gray-200 bg-white rounded-md">
                  <div :id="meetingspace.spacedata.cSlug"
                       class="spaceanchor grid grid-cols-5 gap-4 px-1 sm:pl-4 lg:pr-6 py-2 sm:py-6"
                       :class="{'hidden' : this.$store.state.displaytype == 'clean'}"
                  >
                    <div class="col-span-1">
                      <img class="float-right w-16 md:w-32 rounded-md"
                           :src="this.$store.state.cUrlplanner+'/images/spaces/'+meetingspace.spacedata.cSpaceimage"
                           alt=""/>
                    </div>
                    <div class="col-span-4">
                      <div class="grid grid-cols-4">
                        <div class="col-span-4">
                          <h2 class="text-2xl md:text-4xl pb-1 float-left">{{ meetingspace.spacedata.cSpace }}</h2>
                          <span
                              class="inline-block float-left lg:float-right items-center rounded-md bg-orange-200 m-1 px-2 md:px-4.5 py-0.5 text-xs md:text-sm font-medium text-orange-900">{{
                              meetingspace.spacedata.cSpacetype
                            }}</span>
                          <span
                              class="inline-block float-left lg:float-right float-right items-center rounded-md bg-blue-200 m-1 px-2 md:px-4.5 py-0.5 text-xs md:text-sm font-medium text-blue-800">{{
                              meetingspace.spacedata.cLocation
                            }}</span>
                        </div>
                        <div class="col-span-4 text-left text-sm md:text-base">
                          <p class="w-full float-none" v-html="meetingspace.spacedata.cRemarks"></p>
                          <a class="pt-3 block"
                          :href="'whatsapp://send?text=Check deze space bij Loads https://loadsplanner.nl/meetingspaces/'+meetingspace.spacedata.cSlug" data-action="share/whatsapp/share">
                            <ShareIcon class="h-4 mt-1 float-left text-green-700"></ShareIcon>
                            <span class="text-sm text-green-700">{{ $t('SHAREWHATSAPP') }}</span> 
                          </a> 
                        </div>
                        <div class="col-span-4 lg:col-span-4 mb-2" v-if="meetingspace.spacedata.iMaxpeople > 0">
                          <span
                              class="inline-block float-left lg:float-right items-center rounded-md bg-green-200 m-1 px-2 md:px-4.5 py-0.5 text-xs md:text-sm font-medium text-green-900">{{
                              $t('MAX')
                            }} {{ meetingspace.spacedata.iMaxpeople }} {{ $t('PEOPLE') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full text-left mt-3"
                  :class="{'border-t border-gray-300':this.$store.state.displaytype !== 'clean'}">
                    <div class="grid grid-cols-6">
                      <div class="col-span-6 lg:col-span-2 sm:p-4 lg:pl-3">
                        <div class="grid grid-cols-6">
                          <h3 class="col-span-6 font-bold text-xs md:text-base p-1 md:p-0 md:py-3 pr-2 md:pr-2"
                              :class="{'hidden' : this.$store.state.displaytype == 'clean'}"
                          >{{
                              $t('BOOK_MEETINGSPACE_BLOCKS_TITLE').replace('~cSpace~', meetingspace.spacedata.cSpace)
                            }}</h3>
                          <div class="col-span-6 p-1 text-base md:text-base">{{
                              $t('BOOK_MEETINGSPACE_EXPLANATION')
                            }}
                          </div>
                          <!--                          <div class="border border-gray-200 rounded-md col-span-2 p-2 pr-1 md:p-4 align-middle pt-3 md:col-span-6 lg:my-2">-->
                          <!--                            <span class="w-full float-left uppercase font-bold text-xs ">{{ $t('FROMPRICE') }}</span>-->
                          <!--                            <span class="text-lg sm:text-2xl font-extrabold">&euro; 275</span><span class="font-bold text-xs lg:text-lg">/{{ $t('DAYPART') }}</span>-->
                          <!--?                          </div>-->
                          <div class="col-span-4 p-1 lg:col-span-6">
                            <div class="grid grid-cols-7">
                              <div class="col-span-1">
                                <span class="text-xs bg-green-500 text-green-500 rounded-md">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              </div>
                              <div class="col-span-6">
                                <span class="text-xs md:text-base font-bold pl-1">{{ $t('WHOLEDAYAVAILABLE') }}</span>
                              </div>
                              <div class="col-span-1 lg:pt-0">
                                <span class="text-xs bg-orange-500 text-orange-500 rounded-md ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              </div>
                              <div class="col-span-6">
                                <span class="text-xs md:text-base font-bold pl-1">{{ $t('PARTLYAVAILABLE') }}</span>
                              </div>
                              <div class="col-span-1">
                                <span class="text-xs bg-red-500 text-red-500 rounded-md">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              </div>
                              <div class="col-span-6">
                                <span class="text-xs md:text-base font-bold pl-1">{{ $t('NOTAVAILABLE') }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-span-6 lg:col-span-4 p-2 md:p-4">
                        <div class="flex items-center">
                          <h2 class="flex-auto font-semibold text-gray-900 md:p-0 md:py-3 pr-2 md:pr-2">
                            {{ $t(monthinview) }} {{ yearinview }}</h2>
                          <button type="button"
                                  class="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                                  @click="changeMonthinview(-1)"
                          >
                            <span class="sr-only">Previous month</span>
                            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true"/>
                          </button>
                          <button type="button"
                                  class="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                                  @click="changeMonthinview(1)"
                          >
                            <span class="sr-only">Next month</span>
                            <ChevronRightIcon class="h-5 w-5" aria-hidden="true"/>
                          </button>
                        </div>
                        <div
                            class="mt-1 grid grid-cols-7 text-center font-bold text-xs lg:text-xl leading-6 text-gray-900 bold border-b border-gray-300">
                          <div class="border-r border-gray-200">{{ $t('MONDAY').substring(0, 2).toUpperCase() }}</div>
                          <div class="border-r border-gray-200">{{ $t('TUESDAY').substring(0, 2).toUpperCase() }}</div>
                          <div class="border-r border-gray-200">{{
                              $t('WEDNESDAY').substring(0, 2).toUpperCase()
                            }}
                          </div>
                          <div class="border-r border-gray-200">{{ $t('THURSDAY').substring(0, 2).toUpperCase() }}</div>
                          <div class="border-r border-gray-200">{{ $t('FRIDAY').substring(0, 2).toUpperCase() }}</div>
                          <div class="border-r border-gray-200">{{ $t('SATURDAY').substring(0, 2).toUpperCase() }}</div>
                          <div class="">{{ $t('SUNDAY').substring(0, 2).toUpperCase() }}</div>
                        </div>
                        <div class=" grid grid-cols-7 text-sm">
                          <div v-for="(day, dayIdx) in meetingspace.tariffsofspace[0].tariffofmonth"
                               class="py-1.5"
                               :key="day.key"
                               :class="{'border-t border-gray-200' : dayIdx > 6,
                                        'border-r border-gray-200' : (((dayIdx + 1) / 7) - parseInt((dayIdx + 1) / 7)) !== 0
                                        }"
                          >
                            <button type="button"
                                    :class="
                                      [
                                          day.dayparts.length == 0 && 'bg-gray-300 text-gray-600',
                                          day.dayparts.iBookable == 0 && 'bg-red-600 text-white ',
                                          (day.dayparts.iBookable == 1 || day.dayparts.iBookable == 2)  && 'bg-orange-500 text-white ',
                                          day.dayparts.iBookable == 3 && 'bg-green-600 text-white ',
                                          ' text-xs md:text-sm  font-bold mx-auto flex h-10 w-10 items-center justify-center rounded-full'
                                       ]"
                                    @click="popOver($event, day)"
                            >
                              <time :datetime="day.date">{{ day.key.split('-').pop().replace(/^0/, '') }}</time>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tariffPopover" style="position:absolute; top: -400px;"
                   class="speech-bubble bg-white p-3 border border-gray-300 w-40 sm:w-56 rounded-md z-99"
              >
                <button type="button" class="w-6 h-6 relative -top-3 float-right bg-white" @click="closePopover()">
                  <svg class="w-6 h-6 fill-gray-400" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
                <div class="w-full text-sm sm:text-base font-bold">
                  {{ (currentday.key ? currentday.key.split('-').reverse().join('-') : '') }}
                </div>
                  <span>{{currentday.jSpacetimes}}</span>
                  <div class="grid grid-cols-2 my-2"
                     v-for="daypart in currentday.dayparts" :key="daypart.cDaypart" :daypart="daypart"
                >
                  <div class="font-bold text-left" v-if="daypart.cDaypart !== undefined">
                    <span class="text-xs sm:text-sm font-bold w-full">{{ $t("" + daypart.cDaypart + "") }}</span><br>
<!--                    <span class="text-xs sm:text-sm  w-full">{{ $t("" + daypart.cDaypart + "_TIMES") }}</span>-->
                    <span class="text-xs sm:text-sm  w-full">{{ daypart.times }}</span>
                  </div>
                  <button type="button"
                          v-if="daypart.lBookable == '1' && daypart.cDaypart !== undefined"
                          :disabled="this.$store.state.displaytype == 'cleano'"
                          class="z-0 justify-center w-14 sm:w-20 inline-flex items-center rounded-md border border-transparent ml-2 px-0  py-1  text-xs sm:text-sm  font-medium text-white shadow-sm hover:bg-green-700 bg-green-600"
                          @click="makeReservation(daypart, currentday)"
                          :class="{ 'bg-orange-300' : reservationids.indexOf(daypart.id) > -1,
                                                    'hover:bg-orange-400' : reservationids.indexOf(daypart.id) > -1,
                                          }"
                  >
                    <span class="font-bold">&euro;{{ daypart.dTariff.replace(",", ".") }}</span>
                  </button>
                  <button type="button"
                          class="z-0 justify-center w-14 sm:w-20  inline-flex items-center rounded-md border border-transparent ml-2 px-0 py-1 text-xs md:text-sm font-medium text-white shadow-sm hover:bg-red-700 bg-red-400 cursor-not-allowed opacity-60"
                          v-if="daypart.lBookable == '0' && daypart.cDaypart !== undefined"
                          disabled="disabled"
                  >
                     <span class="justify-center">
                          <svg class="h-3.5 w-3.5 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"
                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                          clip-rule="evenodd"></path></svg>
                          </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.spaceanchor {
  scroll-margin-top: 200px;
}
</style>
<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
  ShareIcon
} from '@heroicons/vue/24/solid';


export default {
  name: 'MeetingSpaces',
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ExclamationTriangleIcon,
    XMarkIcon,
    ShareIcon
  },
  created() {
  },
  data() {
    return {
      meetingspacesearch: ''
    }
  },
  computed: {
    currentmonth() {
      const dtNow = new Date;
      return dtNow.getFullYear() + (dtNow.getMonth() + 1).toString().padStart(2, '0')
    },
    yearinview() {
      return this.$store.state.monthinview.monthfrom.substring(0, 4);
    },
    monthinview() {
      return this.$store.state.months[parseInt(this.$store.state.monthinview.monthfrom.substring(4, 6))];
    },
    meetingspaces() {
      return this.$store.state.meetingspaces
    },
    reservations() {
      return this.$store.state.reservations
    },
    reservationids() {
      return this.$store.state.reservationids
    },
    bookedextraproducts() {
      return this.$store.state.bookedextraproducts.length
    },
    isLoading() {
      return this.$store.state.isloading;
    },
    displayCarton() {
      return this.$store.state.displaycart
    },
    currentday() {
      return this.$store.state.currentday
    }
  },
  methods: {
    getImgsrc(space) {
      space.cSpaceimage = ''
    },
    changeMonthinview(iDelta) {
      this.closePopover()
      this.$store.dispatch('setMonthinview', ['meetingspaces', iDelta])
    },
    updateMeetingspacesearch() {
      this.$store.dispatch('updateMeetingspacesearch', [this.meetingspacesearch, 'meetingspaces'])
    },
    clearMeetingspacesearch() {
      this.meetingspacesearch = '';
      this.$store.dispatch('updateMeetingspacesearch', [this.meetingspacesearch, 'meetingspaces'])
    },
    makeReservation(tariff, day) {
      const id = tariff.id
      if(this.$store.state.displaytype == 'clean' ) {
        // Open the booker in separate tab.
        let iMonth = this.$store.state.monthinview.monthfrom;
        window.open(location.href.replace('#clean', '')+'#'+iMonth, 'loadsbooker');
      }
      else {
        if (this.$store.state.reservationids.indexOf(id) == -1) {
          this.$store.dispatch('makeReservation', ['meetingspaces', tariff.id, day])
        } else {
          this.$store.dispatch('removeReservation', [tariff, day])
        }
      }
    },
    removeReservation(tariff, day) {
      this.$store.dispatch('removeReservation', [tariff, day])
    },
    disableWeekbutton(month) {
      return month.tariffofmonth.find((t) => {
        return t.lBookable == 0 || this.$store.state.reservationids.indexOf(t.id) > -1
      }) !== undefined
    },
    makeReservationalldays(month) {
      month.tariffofmonth.forEach((d) => {
        this.makeReservation(d)
      });
    },
    displayCart() {
      this.$store.dispatch('displayCart')
    },
    popOver(el, day) {
      let oDay = JSON.parse(JSON.stringify(day));
      if (oDay.dayparts.iBookable !== undefined) {
        const popOver = document.getElementById('tariffPopover')
        const lKeeppopupopen = (popOver.style.top !== '-400px' && (this.$store.state.currentday !== day) ? true : false)
        const isMobile = window.innerWidth < 1140;
        this.$store.dispatch('setCurrentday', [day])

        let position = el.currentTarget.getBoundingClientRect();
        if (!lKeeppopupopen && parseInt(popOver.style.top) > 0) {
          popOver.style.top = '-400px';
        } else {
          // let iLeft = (el.currentTarget.offsetLeft - 80);
          let iLeft = (position.x - 80);
          if (isMobile) {
            iLeft = (position.x - 50)
          }

          if (iLeft < 0) {
            iLeft = 0;
          }

          if ((iLeft + 150) > window.innerWidth) {
            iLeft = window.innerWidth - 150
          }
          popOver.style.left = iLeft + 'px';
          let iAddtop = 48;
          let iSubbottom = 250;
          if (isMobile) {
            iAddtop = 50;
            iSubbottom = 310;
          }
          if ((el.view.innerHeight - el.clientY) > 250) {
            popOver.classList.remove('bottom');
            popOver.classList.add('top');
            popOver.style.top = (el.currentTarget.offsetTop + iAddtop) + 'px';
            popOver.style.top = (position.y + iAddtop) + 'px';
          } else {
            popOver.classList.add('bottom');
            popOver.classList.remove('top');
            popOver.style.top = (position.y - (iSubbottom)) + 'px';
          }
        }
      }
    },
    closePopover() {
      const popOver = document.getElementById('tariffPopover')
      popOver.style.top = '-400px';
    },
    handleScroll() {
      // Close the popover if open
      this.closePopover()
    },
    checkForhash() {
      // Check for a location in the hash. If found filter on the space
      // If the last entry of the hash 'clean' than set displaytype to clean
      const locationHash = location.hash.replaceAll('%23', '#')
      const aHash = locationHash.split("#")
      let iMonth = 0;
      if (aHash[0] == '') {
        aHash.shift()
      }
      if (aHash.length > 1) {
        if (aHash.length == 3) {
          var cDisplay = aHash.pop();
          if(cDisplay == 'clean') {
            this.$store.dispatch('setDisplaytype', cDisplay)
          }
          else {
            iMonth = cDisplay
          }
        }
        if(iMonth !== 0) {
          if((this.$store.state.displaytype !== 'clean')) {
            this.$store.state.lastSearch.monthinview = JSON.parse(JSON.stringify(this.$store.state.monthinview));
          }
          this.$store.state.monthinview.monthfrom = iMonth
          this.$store.state.monthinview.monthto = iMonth
          this.$store.state.hasHash = true
        }
        var cSpace = aHash.pop()
        cSpace = decodeURI(cSpace).replaceAll(' ', '').toLowerCase()

        this.$store.dispatch('updateMeetingspacesearch', [cSpace, 'meetingspaces']);
        this.$store.dispatch('setMonthinview', ['meetingspaces', 0, false])

        this.closePopover()
        //this.meetingspacesearch = this.$store.state.meetingspacesearch;
      } else {
        // Get the spaces
        this.$store.dispatch('setMonthinview', ['meetingspaces'])
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log(to, from, next)
    this.checkForhash()

    this.$parent.$forceUpdate();
  }
  ,
  mounted() {
    // Check for a displayed cart and hide it
    this.$store.dispatch('displayCart',false);

    // Restore the searchvalue
    this.meetingspacesearch = this.$store.state.meetingspacesearch;

    this.checkForhash();

    // window.addEventListener('popstate', function () {
    //   this.$forceUpdate();
    // });
  },
}
</script>
