<template>
  <div class="home bg-slate-100"
       :class="{'hidden' : displayCarton()}"
  >
    <div class="">
      <div class="flex-none">
        <div class="w-full p-0 sm:px-7 sm:py-4 sm:pb-2 border border-gray-200 bg-white">
          <h1 class="text-lg md:text-4xl mt-3 ml-3 sm:mt-0 sm:ml-0 sm:pt-2 sm:pb-4">{{ $t('EXTRAPRODUCTS') }}</h1>
        </div>
        <div class="overflow-y-scroll max-h-screen pb-80 sm:pt-2">
          <div class="w-full ">
            <div class="p-0 sm:p-2 sm:py-1 bg-slate-100">
              <div class="intro m-2 p-5 bg-white border border-gray-200  rounded-md">
                <h2 class="text-left text-xl">{{ $t('EXTRA_WELCOME_TITLE') }}</h2>
                <p class="text-left " v-html="$t('EXTRA_WELCOME_BODY')"></p>
              </div>
            </div>
            <button type="button"
                    class="md:hidden fixed bottom-10 right-10 w-20 h-20 inline-flex justify-center items-center rounded-full border-2 border-white bg-green-500 mt-2 px-3 py-2 text-sm font-medium leading-4 text-white  hover:bg-gray-50 focus:outline-none rounded-md"
                    :class="{'text-white' : reservationids.length > 0  || bookedextraproducts > 0}"
                    v-if="reservationids.length > 0 || bookedextraproducts > 0"
                    @click="displayCart()"
            >
              <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
              </svg>
              <div v-if="reservationids.length > 0 || bookedextraproducts > 0" id="cartcount"
                   class="align-middle w-8 h-6 rounded-full bg-red-500 fixed bottom-20 right-10 font-bold pt-1.5">
                {{ reservationids.length + bookedextraproducts }}
              </div>
            </button>
          </div>
          <div class="w-full float-left">
            <div class="p-0  sm:pt-2 bg-slate-100 pb-1 m-2">
              <div class="intro workspaces m-2 pt-1 bg-white border border-gray-200  rounded-md">
                <div v-for="product in products" :key="product.id" :product="product"
                     class="text-left w-full p-1 sm:p-6 mb-5 border-b border-gray-200 bg-white"
                >
                  <div class="grid grid-cols-2">
                    <div class="col-span-1 font-bold text-lg">
                      {{ product.cProduct }}
                    </div>
                    <div class="col-span-1">
                      <button type="button"
                              class="z-0  float-right justify-center w-16 2xl:w-20 inline-flex items-center rounded-md border border-transparent px-2  py-1 text-base md:text-base font-bold text-white shadow-sm hover:bg-green-700 bg-green-600"
                              @click="bookExtraproduct(product)"
                      >
<!--                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"-->
<!--                             xmlns="http://www.w3.org/2000/svg">-->
<!--                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
<!--                                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>-->
<!--                        </svg>-->
                        &euro;{{ product.dPrice }}
                      </button>
                    </div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="col-span-1">
                      {{ product.cDescription }}
                    </div>
                    <div class="col-span-1 text-right font-bold text-lg" >
<!--                      &euro; {{ product.dPrice }}-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" clear-both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExtraProducts',
  components: {},
  computed: {
    products() {
      return this.$store.state.extraproducts
    },
    reservations() {
      return this.$store.state.reservations
    },
    reservationids() {
      return this.$store.state.reservationids
    },
    bookedextraproducts() {
      return this.$store.state.bookedextraproducts.length
    },

  },
  mounted() {
    this.$store.dispatch('getHome', 'home')

    // Check for a product in the hash. If found scroll to the space
    const aHash = location.hash.split("#")
    if (aHash.length > 1) {
      var cProduct = aHash.pop()
      cProduct = decodeURI(cProduct).replaceAll(' ', '').toLowerCase()
      setTimeout(() => {
        let oProduct = this.$store.state.extraproducts.find((p) => {
          if (p.cSlug == cProduct) {
            return p
          }
        });
        this.bookExtraproduct(oProduct)
      }, 1000)
    }
  },
  methods: {
    bookExtraproduct(product) {
      this.$store.dispatch('bookExtraproduct', [product])
    },
    displayCarton() {
      return this.$store.state.displaycart
    },
    displayCart() {
      this.$store.dispatch('displayCart')
    }
  }
}
</script>

<style scoped>
.contact a,
.contact a:link,
.contact a:visited,
.contact a:active {
  text-decoration: underline !important;
}

</style>
