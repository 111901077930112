import {
    createStore
} from "vuex";
import apiService
    from "@/services/ApiService";
import i18n
    from "@/localization";
import ApiService
    from "@/services/ApiService";
import {
    useNProgress
} from '@vueuse/integrations/useNProgress'

const {isLoading} = useNProgress()

import {
    v4 as uuidv4
} from 'uuid'
// import NProgress
//     from "nprogress";

export default createStore({
    state: {
        user: 'loadsbooker',
        spaces: [],
        space: {},
        language: 'nl-NL',
        workplacecount: 0,
        meetingspacecount: 0,
        workplaces: [],
        meetingspaces: [],
        currentday: [],
        monthinview: {},
        workplacesearch: "",
        meetingspacesearch: "",
        reservations: [],
        reservationids: [],
        bookedextraproducts: [],
        bookedextraproductids: [],
        weekdays: [],
        months: [],
        products: [],
        extraproducts: [],
        totalamount: 0,
        agreetoterms: false,
        remarks: '',
        reference: '',
        email: '',
        discount: 0,
        bookername: '',
        bookercompany: '',
        bookercompanyaddress: '',
        bookercompanypostalcode: '',
        bookercompanycity: '',
        bookernameneeded: false,
        cartId: null,
        bookingmessage: '',
        currentmonth: '',
        isloading: isLoading,
        displaycart: false,
        lastSearch: {},
        cUrlplanner: '',
        displaytype: 'full',
        currentRoute: '',
        hasHash: false
    }
    ,
    mutations: {
        SET_HOME(state, home) {
            state.months = home.prefills.months
            state.weekdays = home.prefills.weekdays
            state.products = home.prefills.products

            home.data.forEach((spacetype) => {
                switch (spacetype.cTarifftype) {
                    case 'DAY_PART':
                        state.meetingspacecount = spacetype.count
                        break;
                    case 'MONTH_WEEKDAY':
                        state.workplacecount = spacetype.count
                        break;
                }
            })

            var oExtraproducts = []
            var aExtraproductids = []
            state.products.forEach((p) => {
                if (p.lStandalone == 1 && aExtraproductids.indexOf(p.id) == -1) {
                    oExtraproducts.push(p)
                    aExtraproductids.push(p.id)
                }
            });
            state.extraproducts = oExtraproducts

        },
        SET_SPACE(state, space) {
            state.space = space;
        },
        SET_SPACES(state, [type, spaces]) {
            state.spaces = spaces;
            state[type] = [];
            if (state.spaces.data.length > 0) {
                state[type] = state.spaces.data

                // Set the prefills
                state.months = state.spaces.prefills.months
                state.weekdays = state.spaces.prefills.weekdays
                if (state.tariffsflat == undefined) {
                    state.tariffsflat = [];
                }
                state.tariffsflat[type] = state.spaces.prefills.tariffsflat
                state.products = state.spaces.prefills.products

            }
        },
        SET_LANGUAGE(state, language) {
            state.language = language
        },
        SET_TRANSLATIONS(state, translations) {
            state.translations = translations;
        },
        SET_MONTHINVIEW(state, aMonths) {
            state.monthinview = {
                "monthfrom": aMonths[0],
                "monthto": aMonths[1]
            }
        },
        SET_WORKPLACESEARCH(state, workplacesearch) {
            state.workplacesearch = workplacesearch
        },
        SET_MEETINGSPACESEARCH(state, meetingspacesearch) {
            state.meetingspacesearch = meetingspacesearch
        },
        SET_RESERVATION(state, [type, tariffId, day]) {
            state.bookingmessage = ''
            state.reservationids.push(tariffId)
            var oTariff = state.tariffsflat[type].find((t) => {
                return t.id == tariffId
            })
            if (oTariff.cTarifftype == 'DAY_PART') {
                oTariff.dDiscount = oTariff.dTariff * (state.discount / 100)
                oTariff.dDiscountedtariff = oTariff.dTariff - oTariff.dDiscount
                oTariff.dDiscountedtariff = oTariff.dDiscountedtariff.toFixed(2).replace(".", ",")
                if (day !== undefined) {
                    day.dayparts.iBookable--;
                }
            }

            var oProducts = []
            state.products.forEach((p) => {
                if (p.idTarifftype == oTariff.idTarifftype) {
                    var p2 = JSON.parse(JSON.stringify(p));
                    p2.iQuantity = 0
                    p2.iTotal = 0
                    oProducts.push(p2)
                }
            });
            oTariff.products = oProducts;
            state.reservations.push(oTariff)
        },
        REMOVE_RESERVATION(state, [reservation, day]) {
            const iReservation = state.reservationids.indexOf(reservation.id)
            if (iReservation > -1) {
                state.reservationids.splice(iReservation, 1)
                state.reservations.splice(iReservation, 1)
            }

            if (reservation.cDaypart !== undefined && reservation.cDaypart !== '') {
                if(day == null) {
                    // Get the day from the meetingspaces
                    let key = reservation.iMonth.substr(0, 4) + '-' + reservation.iMonth.substr(4, 6) + '-' + reservation.iMonthday.padStart(2, '0')
                    let oSpace = state.meetingspaces.find(s => s.spacedata.idSpace == reservation.idSpace)
                    day = oSpace.tariffsofspace[0].tariffofmonth.find(d => d.key == key)
                }
                day.dayparts.iBookable++;
            }
        },
        SET_EXTRAPRODUCTBOOKING(state, [product]) {
            if (product !== undefined) {
                let productId = product.id;
                let oBookedextraproduct = state.bookedextraproducts.find((e) => {
                    if (e.id == productId) {
                        return e
                    }
                })
                if (oBookedextraproduct) {
                    oBookedextraproduct.iQuantity += 1
                } else {
                    let oBookedextraproduct = JSON.parse(JSON.stringify(product))
                    oBookedextraproduct.iQuantity = 1
                    state.bookedextraproducts.push(oBookedextraproduct)
                }
            }
        },
        REMOVE_EXTRAPRODUCTBOOKING(state, [product]) {
            if (product !== undefined) {
                let productId = product.id;
                let iIndex = state.bookedextraproducts.findIndex((e) => {
                    if (e.id == productId) {
                        return e
                    }
                })
                if (iIndex > -1) {
                    state.bookedextraproducts.splice(iIndex, 1)
                }
            }
        },
        SET_TOTALAMOUNT(state) {
            var dTotal = 0;
            state.reservations.forEach((r) => {
                dTotal += parseFloat(r.dTariff)
                if (r.dDiscount > 0) {
                    dTotal -= r.dDiscount
                }
                r.products.forEach((p) => {
                    dTotal += parseFloat(p.dPrice * p.iQuantity)
                })
            })
            state.bookedextraproducts.forEach((p) => {
                dTotal += parseFloat(p.dPrice * p.iQuantity)
            })
            state.totalamount = dTotal.toFixed(2)
        },
        SET_AGREETOTERMS(state, agree) {
            state.agreetoterms = agree
        },
        SET_BOOKERNAME(state, bookername) {
            state.bookername = bookername
        },
        SET_BOOKERCOMPANY(state, bookercompany) {
            state.bookercompany = bookercompany
        },
        SET_BOOKERCOMPANYADDRESS(state, bookercompanyaddress) {
            state.bookercompanyaddress = bookercompanyaddress
        },
        SET_BOOKERCOMPANYPOSTALCODE(state, bookercompanypostalcode) {
            state.bookercompanypostalcode = bookercompanypostalcode
        },
        SET_BOOKERCOMPANYCITY(state, bookercompanycity) {
            state.bookercompanycity = bookercompanycity
        },
        SET_EMAIL(state, email) {
            state.email = email
            // Set a cooke; for 90 days
            let expiration = (new Date(Date.now() + 86400 * 1000 * 90)).toUTCString()

            $cookies.set('emailaddress', email, expiration) // eslint-disable-line no-undef
            ApiService.checkEmail(email)
                .then(response => {
                    if (response.data.successful) {
                        state.discount = response.data.ptDiscount
                        state.bookernameneeded = false
                    } else {
                        state.bookernameneeded = true
                        state.discount = 0
                    }
                    state.reservations.forEach((reservation) => {
                        if (reservation.cTarifftype == 'DAY_PART') {
                            reservation.dDiscount = reservation.dTariff * (state.discount / 100)
                            reservation.dDiscountedtariff = reservation.dTariff - reservation.dDiscount
                            reservation.dDiscountedtariff = reservation.dDiscountedtariff.toFixed(2).replace(".", ",")
                        }
                    })
                })
        },
        SET_REMARKS(state, remarks) {
            state.remarks = remarks
        },
        SET_REFERENCE(state, reference) {
            state.reference = reference
        },
        SET_CARTID(state) {
            if (state.cartId == null) {
                state.cartId = uuidv4()
            }
        },
        SET_ENVIRONMENT(state, environment) {
            state.environment = environment;
        },
        SET_RESERVATIONFIELD(state, options) {
            const iReservation = state.reservationids.indexOf(options.id)
            state.reservations[iReservation][options.field] = options.value
        },
        SET_TARIFFFIELD(state, [type, options]) {
            var oTariff = state.tariffsflat[type].find((t) => {
                return t.id == options.id
            })
            oTariff[options.field] = options.value
        },
        RESERVATION_SUCCESSFUL(state) {
            // Reservation successful.
            // Empty the reservations array,
            // display message
            // create a new cartid
            // and refetch the spaces
            state.reservations = []
            state.reservationids = []
            state.bookedextraproducts = []
            state.bookingmessage = 'RESERVATION_SUCCESSFUL'
            state.cartId = uuidv4()
            this.dispatch('fetchSpaces', [this.state, 'workplaces', true])
            this.dispatch('fetchSpaces', [this.state, 'meetingspaces', true])
        },
        DISPLAY_CART(state, lDisplay) {
            state.displaycart = lDisplay
        },
        SET_CURRENTDAY(state, currentday) {
            let idTariff = currentday.dayparts[0].id
            let oSpace = state.tariffsflat.meetingspaces.find((t) => {if(t.id == idTariff){return t}})
            // let oSpacefull = state.spaces.data.find((s) => {if(s.spacedata.idSpace == oSpace.idSpace) return s})
            let oSpacefull = state.meetingspaces.filter((s) => {if(s.spacedata.idSpace == oSpace.idSpace) {return s}})[0]
            if(oSpacefull.spacedata.jSpacetimes !== undefined) {
                let jSpacetimes = JSON.parse(oSpacefull.spacedata.jSpacetimes)
                currentday.dayparts[0].times = jSpacetimes[0].times
                currentday.dayparts[1].times = jSpacetimes[1].times
                currentday.dayparts[2].times = jSpacetimes[2].times
            }
            state.currentday = currentday
        },
        SET_URL(state, cUrl) {
            state.cUrlplanner = cUrl
        },
        SET_DISPLAYTYPE(state, cDisplaytype) {
            state.displaytype = cDisplaytype
        },
        SET_CURRENTROUTE(state, cRoute) {
            state.currentRoute = cRoute
        }
    },
    actions: {
        getHome({commit}) {
            return apiService.getHome()
                .then(response => {
                    commit('SET_HOME', response.data)
                })
                .catch(error => {
                    throw(error)
                })
        },
        fetchSpaces({commit}, [state, type, force = false]) {
            let search = '';
            console.log('fetch')
            switch (type) {
                case 'workplaces':
                    search = state.workplacesearch;
                    break;
                case 'meetingspaces':
                    search = state.meetingspacesearch;
                    break;
            }

            const lastSearch = state.lastSearch[type] || {};
            if (
                lastSearch == {} ||
                JSON.stringify(state.monthinview) !== JSON.stringify(lastSearch.monthinview) ||
                search !== lastSearch.search ||
                force == true ||
                this.state.hasHash
            ) {
                return apiService.getSpaces(
                    type,
                    state.monthinview.monthfrom,
                    state.monthinview.monthto,
                    search
                )
                    .then(response => {
                        commit('SET_SPACES', [type, response.data])
                        state.lastSearch[type] = {
                            monthinview: state.monthinview,
                            search: search
                        }
                        this.state.hasHash = false
                    })
                    .catch(error => {
                        throw(error)
                    })
            } else {
                return state[type]
            }
        },
        fetchSpace({commit}, state, id) {
            // Search for the space in the existing dataset
            const cachedSpace = state.spaces.find(space => space.id === id);
            if (cachedSpace) {
                commit('SET_SPACE', cachedSpace)
            } else {
                return apiService.getSpace(id)
                    .then(response => {
                        commit('SET_SPACE', response.data)
                    })
                    .catch(error => {
                        throw error
                    })
            }
        },
        setLanguage({commit}, language) {
            commit('SET_LANGUAGE', language)
            i18n.locale = language
        },
        setMonthinview({commit}, [cType, iDelta = 0, lNofetch = false] ) {
            let oMonthinview = this.state.monthinview;
            let cMonthfrom = ''
            let cMonthto = ''

            if (oMonthinview.monthfrom == undefined) {
                const dtNow = new Date();
                let cYear = dtNow.getFullYear()
                let iMonthfrom = (dtNow.getMonth())
                if (cType == 'workplaces') {
                    let iYeardelta = 0;
                    if (iMonthfrom < 0) {
                        iMonthfrom = iMonthfrom + 12
                        iYeardelta = -1
                    }
                    if (iMonthfrom > 12) {
                        iMonthfrom = iMonthfrom - 12
                        iYeardelta = +1
                    }
                    let cYearfrom = cYear + iYeardelta
                    cMonthfrom = cYearfrom.toString() + iMonthfrom.toString().padStart(2, '0')

                    let iMonthto = iMonthfrom + 3
                    if (iMonthto > 12) {
                        iMonthto = iMonthto - 12
                        iYeardelta = 1
                    }

                    let cYearto = cYear + iYeardelta
                    cMonthto = cYearto.toString() + iMonthto.toString().padStart(2, '0')
                }
                if (cType == 'meetingspaces') {
                    iMonthfrom++;
                    cMonthfrom = cYear + iMonthfrom.toString().padStart(2, '0');
                    cMonthto = cMonthfrom;
                }

            } else {
                cMonthfrom = oMonthinview.monthfrom
                cMonthto = oMonthinview.monthto
                if (cType == 'workplaces' && cMonthto == cMonthfrom) {
                    let iMonthto = parseInt(cMonthfrom) + 3
                    let iMonth = iMonthto.toString().substring(4, 6)
                    if (iMonth > 12) {
                        let iYear = iMonthto.toString().substring(0, 4);
                        iYear++;
                        iMonth = iMonth - 12
                        cMonthto = iYear.toString() + iMonth.toString().padStart(2, '0')
                    }
                }
                let iYearfrom = parseInt(cMonthfrom.substring(0, 4))
                let iMonthfrom = parseInt(cMonthfrom.substring(4, 6))
                iMonthfrom = iMonthfrom + iDelta
                if (iMonthfrom > 12) {
                    iMonthfrom = iMonthfrom - 12
                    iYearfrom = iYearfrom + 1
                }
                if (iMonthfrom < 1) {
                    iMonthfrom = iMonthfrom + 12
                    iYearfrom = iYearfrom - 1
                }
                cMonthfrom = iYearfrom.toString() + iMonthfrom.toString().padStart(2, '0')

                let iYearto = parseInt(cMonthto.substring(0, 4))
                let iMonthto = parseInt(cMonthto.substring(4, 6))

                iMonthto = iMonthto + iDelta
                if (iMonthto > 12) {
                    iMonthto = iMonthto - 12
                    iYearto = iYearto + 1
                }
                if (iMonthto < 1) {
                    iMonthto = iMonthto + 12
                    iYearto = iYearto - 1
                }
                cMonthto = iYearto.toString() + iMonthto.toString().padStart(2, '0')
            }
            commit('SET_MONTHINVIEW', [cMonthfrom, cMonthto])
            if (!lNofetch) {
                this.dispatch('fetchSpaces', [this.state, cType])
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        updateWorkplacesearch({commit}, [workplaceSearch, cType]) {
            commit('SET_WORKPLACESEARCH', workplaceSearch)
            this.dispatch('fetchSpaces', [this.state, cType])
                .catch(error => {
                    console.log(error)
                })
        },
        updateMeetingspacesearch({commit}, [meetingspaceSearch, cType]) {
            commit('SET_MEETINGSPACESEARCH', meetingspaceSearch)
            this.dispatch('fetchSpaces', [this.state, cType])
                .catch(error => {
                    console.log(error)
                })
        },
        makeReservation({commit}, [type, tariff, day]) {
            commit('SET_RESERVATION', [type, tariff, day])
            commit('SET_TOTALAMOUNT')
        },
        bookExtraproduct({commit}, [product]) {
            this.state.bookingmessage = ''
            commit('SET_EXTRAPRODUCTBOOKING', [product])
            commit('SET_TOTALAMOUNT')
        },
        removeReservation({commit}, [reservation, day]) {
            commit('REMOVE_RESERVATION', [reservation, day])
            commit('SET_TOTALAMOUNT')

            // Refetch the spaces again.
            //this.dispatch('fetchSpaces', this.state)
        },
        removeBookedextraproduct({commit}, product) {
            commit('REMOVE_EXTRAPRODUCTBOOKING', [product])
            commit('SET_TOTALAMOUNT')
        },
        setTotalamount({commit}) {
            commit('SET_TOTALAMOUNT')
        },
        setAgreetoterms({commit}, agree) {
            commit('SET_AGREETOTERMS', agree)
        },
        setBookername({commit}, bookername) {
            commit('SET_BOOKERNAME', bookername)
        },
        setBookercompany({commit}, bookercompany) {
            commit('SET_BOOKERCOMPANY', bookercompany)
        },
        setBookercompanyaddress({commit}, bookercompanyaddress) {
            commit('SET_BOOKERCOMPANYADDRESS', bookercompanyaddress)
        },
        setBookercompanypostalcode({commit}, bookercompanypostalcode) {
            commit('SET_BOOKERCOMPANYPOSTALCODE', bookercompanypostalcode)
        },
        setBookercompanycity({commit}, bookercompanycity) {
            commit('SET_BOOKERCOMPANYCITY', bookercompanycity)
        },
        setEmail({commit}, email) {
            commit('SET_EMAIL', email)
        },
        setRemarks({commit}, remarks) {
            commit('SET_REMARKS', remarks)
        },
        setReference({commit}, reference) {
            commit('SET_REFERENCE', reference)
        },
        setCartid({commit}) {
            commit('SET_CARTID')
        },
        setEnvironment({commit}, environment) {
            commit('SET_ENVIRONMENT', environment)
        },
        doBooking({commit}) {
            var oBooking = {
                uuid: this.state.cartId,
                cEmail: this.state.email,
                lAgreetoterms: this.state.agreetoterms,
                aReservedtariffs: this.state.reservations,
                aExtraproducts: this.state.bookedextraproducts,
                dTotalamount: this.state.totalamount,
                language: this.state.language,
                cRemarks: this.state.remarks,
                cReference: this.state.reference
            }
            if (this.state.bookernameneeded) {
                oBooking.cBookername = this.state.bookername
                oBooking.cBookercompany = this.state.bookercompany
                oBooking.cBookercompanyaddress = this.state.bookercompanyaddress
                oBooking.cBookercompanypostalcode = this.state.bookercompanypostalcode
                oBooking.cBookercompanycity = this.state.bookercompanycity
            }
            ApiService.postBooking(oBooking)
                .then(function (response) {
                    if (response.data.successful == false) {
                        response.data.alreadybooked.forEach((idTariff) => {
                            commit('SET_RESERVATIONFIELD', {
                                "id": idTariff,
                                "field": 'bookingerror',
                                "value": 'TIME_SLOT_ALREADY_BOOKED'
                            })
                        })
                    }
                    if (response.data.successful == true) {
                        commit('RESERVATION_SUCCESSFUL', {'idBooking': response.id})
                    }
                })
        },
        displayCart({commit}, lDisplaycart) {
            if (lDisplaycart == undefined) {
                lDisplaycart = !this.state.displaycart;
            }
            commit('DISPLAY_CART', lDisplaycart);
        },
        setCurrentday({commit}, [day]) {
            commit('SET_CURRENTDAY', day)
        },
        setLoadsplannerUrl({commit}) {
            var cUrl = '';
            switch (location.host) {
                case 'localhost:8080':
                    cUrl = 'https://www.loadsplanner.nl/test';
                    break;
                case 'loadsbooker.loadsplanner.nl':
                    cUrl = 'https://www.loadsplanner.nl/test'
                    break;
                default:
                    cUrl = 'https://www.admin.loadsplanner.nl'
            }
            commit('SET_URL', cUrl)
        },
        setDisplaytype({commit}, cDisplaytype) {
            commit('SET_DISPLAYTYPE', cDisplaytype);
        },
        setCurrentroute({commit}, cRoute) {
            commit('SET_CURRENTROUTE', cRoute);
        },
        setWorkplacesearch({commit}, workplacesearch) {
            commit('SET_WORKPLACESEARCH', workplacesearch)
        },
        setMeetingspacesearch({commit}, meetingspacesearch) {
            commit('SET_MEETINGSPACESEARCH', meetingspacesearch)
        },
        SET_MEETINGSPACESEARCH(state, meetingspacesearch) {
            state.meetingspacesearch = meetingspacesearch
        },
        getMeetingspaceday(reservation) {
            let key = reservation.iMonth.substr(0, 4) + '-' + reservation.iMonth.substr(4, 6) + '-' + reservation.iMonthday.padStart(2, '0')
            let oSpace = this.state.meetingspaces.find(s => s.spacedata.idSpace == reservation.idSpace)
            let day = oSpace.tariffsofspace[0].tariffofmonth.find(d => d.key == key)
            return day
        }
    },
    modules: {},
    methods: {
    }
})
