<template>
  <div class="workplaces w-full lg:block"
       :class="{
          'hidden' : displayCarton,
          'bg-slate-100' : this.$store.state.displaytype !== 'clean'
      }"
  >
    <div class="w-full ">
      <div class="w-full">
        <div
            class="z-50 grid grid-cols-3 p-0 sm:px-7 sm:py-4 sm:pt-5 sm:pb-1  border border-gray-200 bg-white sticky top-16"
            :class="{'hidden' : this.$store.state.displaytype == 'clean'}"
        >
          <div>
            <h1 class="text-2xl sm:text-4xl mt-3 ml-3 sm:mt-1 sm:ml-0">{{ $t('WORKPLACES') }}</h1>
          </div>
          <div class="text-left col-span-3 md:col-span-2 pl-2 pb-4 md:text-right md:pr-3">
            <label for="workplacesearch" class="sr-only">Workplacesearch</label>
            <div class="mt-1">
              <input type="text" name="workplacesearch" id="workplacesearch" v-model="workplacesearch"
                     class="border-gray-200 w-4/5 border rounded-md h-9 p-2 focus:outline-none"
                     @keydown.enter="updateWorkplacesearch()"
                     :placeholder="$t('SEARCHFORGENERAL')"
              />
              <button type="button"
                      v-if="workplacesearch !== ''"
                      :title="$t('CLEARSELECTION')"
                      class="text-gray-500 hover:text-gray-800 absolute right-16 bottom-5 sm:bottom-6 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      @click="clearWorkplacesearch()"
              >
                <XMarkIcon class="h-5 w-5 text-grey-400"></XMarkIcon>
              </button>
              <button type="button"
                      class="inline-flex items-center rounded-md border border-gray-300 bg-white mt-2 px-2 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                      @click="updateWorkplacesearch()"
              >
                <span v-if="!isLoading">
                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"></path>
                    </svg>
                </span>
                <span v-if="isLoading" class="inline-flex">
                    <svg class="animate-spin h-4 w-4 blue" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="silver" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </span>
              </button>
              <!--              <button type="button" class="inline-flex items-center rounded-md border border-gray-300 bg-white mt-2 px-2 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"-->
              <!--                      v-if="workplacesearch !== ''"-->
              <!--                      @click="clearWorkplacesearch()"-->
              <!--              >-->
              <!--                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
              <!--                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
              <!--                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>-->
              <!--                  </svg>-->
              <!--                </svg>-->
              <!--              </button>-->
              <button type="button"
                      class="lg:hidden fixed bottom-10 right-10 w-20 h-20 inline-flex justify-center items-center rounded-full border-2 border-white hover:bg-green-500 bg-green-500 mt-2 px-3 py-2 text-sm font-medium leading-4 text-white  hover:bg-gray-50 focus:outline-none"
                      :class="{'text-white' : reservationids.length > 0 || bookedextraproducts > 0}"
                      v-if="reservationids.length > 0 || bookedextraproducts > 0 "
                      @click="displayCart()"
              >
                <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                </svg>
                <span v-if="reservationids.length > 0 || bookedextraproducts > 0" id="cartcount"
                      class="align-middle w-8 h-6 rounded-full bg-red-500 fixed bottom-20 right-10 font-bold pt-1.5">
                  {{ reservationids.length + bookedextraproducts }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="overflow-y-scroll max-h-screen pb-44">
          <div class="w-full">
            <div class="p-0 sm:p-2"
                 :class="{
                  'bg-slate-100' : this.$store.state.displaytype !== 'clean'
              }"
            >
              <div id="workplaces" class="intro m-0 p-0 sm:m-2 sm:p-1 lg:p-2 bg-transparent">
                <div v-if="workplaces.length == 0">
                  <div class="rounded-md bg-yellow-50 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true"/>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-lg font-medium text-yellow-800 text-left">
                          <span v-if="isLoading">{{ $t('LOADING') }}</span>
                          <span v-if="!isLoading">{{ $t('NOSPACESFOUND') }}</span>
                        </h3>
                        <div class="mt-2 text-sm text-yellow-700">
                          <p v-if="isLoading" class="blinking">{{ $t('LOADING_EXPLANATION') }}</p>
                          <p v-if="!isLoading">{{ $t('NOSPACESFOUND_EXPLANATION') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="workplace in workplaces" :key="workplace.spacedata.id" :workplace="workplace"
                     class="w-full p-0 lg:p-y8 mb-3 border border-gray-200 bg-white rounded-md">
                  <div :id="workplace.spacedata.cSlug"
                       class="spaceanchor grid grid-cols-5 gap-4 px-1 sm:pl-4 lg:pr-6 py-2 sm:py-6"
                       :class="{'hidden' : this.$store.state.displaytype == 'clean'}"
                  >
                    <div class="justify-center">
                      <img class="float-none w-16  md:w-24 rounded-md"
                           :src="this.$store.state.cUrlplanner+'/images/spaces/'+workplace.spacedata.cSpaceimage" alt=""
                           v-if="workplace.spacedata.cSpaceimage"
                      />
                    </div>
                    <div class="col-span-4 pr-1.5">
                      <div class="grid grid-cols-4">
                        <div class="col-span-4 lg:col-span-2">
                          <h2 class="text-2xl md:text-3xl pb-1">{{ workplace.spacedata.cSpace }}</h2>
                        </div>
                        <div class="col-span-4 lg:col-span-2 mb-2">
                          <span
                              class="inline-block float-left lg:float-right items-center rounded-md bg-orange-200 m-1 px-2 md:px-4.5 py-0.5 text-xs md:text-sm font-medium text-orange-900">{{
                              workplace.spacedata.cSpacetype
                            }}</span>
                          <span
                              class="inline-block float-left lg:float-right float-right items-center rounded-md bg-blue-200 m-1 px-2 md:px-4.5 py-0.5 text-xs md:text-sm font-medium text-blue-800">{{
                              workplace.spacedata.cLocation
                            }}</span>
                        </div>
                        <div class="col-span-4 text-left text-sm md:text-base">
                          <p class="w-full float-none" v-html="workplace.spacedata.cRemarks"></p>
                          <a class="pt-3 block"
                          :href="'whatsapp://send?text=Check deze space bij Loads https://loadsplanner.nl/workplaces/'+workplace.spacedata.cSlug" data-action="share/whatsapp/share">
                            <ShareIcon class="h-4 mt-1 float-left text-green-700"></ShareIcon>
                            <span class="text-sm text-green-700">{{ $t('SHAREWHATSAPP') }}</span> 
                          </a> 
                        </div>
                        <div class="col-span-4 lg:col-span-4 mb-2"
                             v-if="workplace.spacedata.cTarifftype !== 'TEAMFLEX' && workplace.spacedata.iMaxpeople > 0">
                          <span
                              class="inline-block float-left lg:float-right items-center rounded-md bg-green-200 m-1 px-2 md:px-4.5 py-0.5 text-xs md:text-sm font-medium text-green-900">
                            {{ $t('MAX') }} {{
                              workplace.spacedata.iMaxpeople
                            }} {{ $t((workplace.spacedata.iMaxpeople == 1 ? 'PERSON' : 'PEOPLE')) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full text-left px-1 sm:pl-4 lg:pr-6 py-2 sm:py-4"
                       :class="{
                            'border-t border-gray-300' : this.$store.state.displaytype !== 'clean'
                      }"
                  >
                    <h3 class="font-bold text-base md:text-2xl p-3 md:p-0 md:py-3"
                        v-if="workplace.spacedata.cTarifftype !== 'TEAMFLEX'"
                        :class="{'hidden' : this.$store.state.displaytype == 'clean'}"
                    >
                      {{ $t('BOOK_SPACE_BLOCKS_TITLE').replace('~cSpace~', workplace.spacedata.cSpace) }}</h3>
                    <table class="border-collapse border-spacing-1 ml-1">
                      <tr>
                        <th class="align-bottom  pr-2 md:pr-6">
                          <ChevronUpIcon class="h-6 float-right" @click="changeMonthinview(-1)"></ChevronUpIcon>
                        </th>
                        <th class="text-center">
                          <span class="text-xs">{{ $t('EVERY').toLowerCase() }}</span><br/>
                          <span class="text-xs md:text-base">{{
                              $t('MONDAY').toUpperCase().substring(0, 2)
                            }}</span><br/>
                        </th>
                        <th class="text-center">
                          <span class="text-xs">{{ $t('EVERY').toLowerCase() }}</span><br/>
                          <span class="text-xs md:text-base">{{
                              $t('TUESDAY').toUpperCase().substring(0, 2)
                            }}</span><br/>
                        </th>
                        <th class="text-center">
                          <span class="text-xs">{{ $t('EVERY').toLowerCase() }}</span><br/>
                          <span class="text-xs md:text-base">{{
                              $t('WEDNESDAY').toUpperCase().substring(0, 2)
                            }}</span><br/>
                        </th>
                        <th class="text-center">
                          <span class="text-xs">{{ $t('EVERY').toLowerCase() }}</span><br/>
                          <span class="text-xs md:text-base">{{
                              $t('THURSDAY').toUpperCase().substring(0, 2)
                            }}</span><br/>
                        </th>
                        <th class="text-center">
                          <span class="text-xs">{{ $t('EVERY').toLowerCase() }}</span><br/>
                          <span class="text-xs md:text-base">{{
                              $t('FRIDAY').toUpperCase().substring(0, 2)
                            }}</span><br/>
                        </th>
                        <th class="text-center"
                            :class="{'hidden' : this.$store.state.displaytype == 'clean'}"
                        >
                          <span class="text-xs">{{ $t('WHOLE').toLowerCase() }}</span><br/>
                          <span class="text-xs md:text-base">{{ $t('WEEK').toUpperCase() }}</span><br/>
                        </th>
                      </tr>
                      <tr v-for="month in workplace.tariffsofspace" :key="month.iMonth" :month="month"
                          class="border-b border-gray-100"
                      >
                        <th class="text-right text-base md:text-base  pr-2 md:pr-6 md:w-32">
                          <span class="text-orange-400 mr-1" v-if="month.iMonth == currentmonth">&bull;</span>
                          <span class="hidden md:inline-block">{{ month.cMonthname }}</span>
                          <span class="md:hidden">{{ month.cMonthname.substr(0, 3) + '.' }}</span>
                        </th>
                        <td v-for="tariff in month.tariffofmonth" :key="tariff.id" :tariff="tariff"
                            class="p-0 md:p-2"
                            :class="{'hidden' : workplace.spacedata.cTarifftype !== 'MONTH_WEEKDAY'}"
                        >
                          <button type="button"
                                  v-if="tariff.lBookable == '1'"
                                  class="z-0 justify-center w-12  xl:w-16 2xl:w-16 inline-flex items-center rounded-md border border-transparent px-1  py-1 text-xs md:text-sm font-medium text-white shadow-sm hover:bg-green-700 bg-green-600"
                                  @click="makeReservation(tariff)"
                                  :class="{'bg-orange-300' : reservationids.indexOf(tariff.id) > -1,'hover:bg-orange-400' : reservationids.indexOf(tariff.id) > -1, 'opacity-60' : month.iMonth < currentmonth, 'cursor-not-allowed' : month.iMonth < currentmonth}"
                                  :disabled="month.iMonth < currentmonth || this.$store.state.displaytype == 'cleano'"
                          >
                            <span class="font-bold">&euro;{{ tariff.dTariff.split(".")[0] }}</span>
                          </button>
                          <button type="button"
                                  class="z-0 justify-center w-12  xl:w-16 2xl:w-16  inline-flex items-center rounded-md border border-transparent px-4 py-1 text-xs md:text-sm font-medium text-white shadow-sm hover:bg-red-700 bg-red-400 cursor-not-allowed opacity-60"
                                  v-if="tariff.lBookable == '0'"
                                  :disabled="month.iMonth < currentmonth || this.$store.state.displaytype == 'cleano'"
                          >
                            <span class="justify-center">
                                <svg class="h-3.5 w-3.5 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"
                                                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                                              clip-rule="evenodd"></path></svg>
                            </span>
                          </button>
                        </td>
                        <td class="p-1"
                            :class="{'hidden' : workplace.spacedata.cTarifftype !== 'MONTH_WEEKDAY'}"
                        >
                          <button type="button"
                                  class="z-0 justify-center w-12  xl:w-16 2xl:w-16  inline-flex items-center rounded-md border border-transparent px-4 py-1 text-xs md:text-sm font-medium text-white shadow-sm  hover:bg-green-700 bg-green-600"
                                  :class="{
                            'opacity-60' : month.iMonth < currentmonth,
                            'cursor-not-allowed' : month.iMonth < currentmonth,
                            'hidden' : workplace.spacedata.cTarifftype !== 'MONTH_WEEKDAY' || this.$store.state.displaytype == 'clean'
                          }"
                                  v-if="!disableWeekbutton(month)"
                                  @click="makeReservationalldays(month)"
                          >
                            <span class="pl-0 ">
                              <PlusIcon class="h-3.5 w-3.5 md:w-5 md:h-5 text-white"></PlusIcon>
                            </span>
                          </button>
                          <button type="button"
                                  class="z-0 justify-center cursor-not-allowed w-12  xl:w-16 2xl:w-16  inline-flex items-center rounded-md border border-transparent px-4 py-1 text-xs md:text-sm font-medium text-white shadow-sm  hover:bg-red-600 bg-red-400  opacity-60"
                                  v-if="disableWeekbutton(month)"
                                  :class="{'hidden' : workplace.spacedata.cTarifftype !== 'MONTH_WEEKDAY' || this.$store.state.displaytype == 'clean'}"
                          >
                            <span class="pl-0 ">
                                <svg class="h-3.5 w-3.5 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"
                                                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                                              clip-rule="evenodd"></path></svg>
                            </span>
                          </button>
                        </td>
                        <td v-for="m in [].constructor(5)" :key="m" :tariff="m"
                            class="p-0 md:p-2"
                            :class="{'hidden' : workplace.spacedata.cTarifftype !== 'TEAMFLEX'}"
                        >
                          <button type="button"
                                  class="z-0 justify-center w-12  xl:w-16 2xl:w-16  inline-flex items-center rounded-md border border-transparent px-4 py-3 text-xs md:text-sm font-medium text-white shadow-sm hover:bg-red-700 bg-red-400 cursor-not-allowed opacity-60"
                                  disabled="disabled"
                          >
                            <span class="justify-center">
                                <svg class="h-3.5 w-3.5 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"
                                                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                                              clip-rule="evenodd"></path></svg>
                            </span>
                          </button>
                        </td>
                        <td v-for="tariff in month.tariffofmonth" :key="tariff.id" :tariff="tariff"
                            class="p-0 md:p-2"
                            :class="{'hidden' : workplace.spacedata.cTarifftype !== 'TEAMFLEX'}"
                        >
                          <button type="button"
                                  v-if="tariff.lBookable == '1'"
                                  class="z-0 justify-center w-12  xl:w-16 2xl:w-16 items-center rounded-md border border-transparent px-1  py-1 text-xs md:text-sm font-medium text-white shadow-sm hover:bg-green-700 bg-green-600"
                                  @click="makeReservation(tariff)"
                                  :class="{'bg-orange-300' : reservationids.indexOf(tariff.id) > -1,'hover:bg-orange-400' : reservationids.indexOf(tariff.id) > -1, 'opacity-60' : month.iMonth < currentmonth, 'cursor-not-allowed' : month.iMonth < currentmonth}"
                                  :disabled="month.iMonth < currentmonth  || this.$store.state.displaytype == 'cleano'"
                          >
                            <div class="font-bold">&euro;{{ tariff.dTariff.split(".")[0] }}</div>
                            <div class="text-xxs">
                              {{
                                parseInt(tariff.iNumbookable) - tariff.iNumbooked - (this.$store.state.reservationids.indexOf(tariff.id) > -1 ? 1 : 0)
                              }} {{ $t('AVAIL') }}
                            </div>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th class=" pr-2 md:pr-6">
                          <ChevronDownIcon class="h-6 float-right" @click="changeMonthinview(1)"></ChevronDownIcon>
                        </th>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.spaceanchor {
  scroll-margin-top: 200px;
}
</style>
<script>
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
  ShareIcon
} from '@heroicons/vue/24/solid';


export default {
  name: 'WorkPlaces',
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
    PlusIcon,
    ExclamationTriangleIcon,
    XMarkIcon,
    ShareIcon
  },
  created() {
  },
  data() {
    return {
      workplacesearch: ''
    }
  },
  computed: {
    currentmonth() {
      const dtNow = new Date;
      return dtNow.getFullYear() + (dtNow.getMonth() + 1).toString().padStart(2, '0')
    },
    workplaces() {
      return this.$store.state.workplaces
    },
    reservations() {
      return this.$store.state.reservations
    },
    reservationids() {
      return this.$store.state.reservationids
    },
    bookedextraproducts() {
      return this.$store.state.bookedextraproducts.length
    },
    isLoading() {
      return this.$store.state.isloading;
    },
    displayCarton() {
      console.log(this.$store.state.displaycart)
      return this.$store.state.displaycart
    },
    // plannerUrl() {
    //   return this.$store.state.
    // }
  },
  methods: {
    getImgsrc(space) {
      space.cSpaceimage = ''
    },
    changeMonthinview(iDelta) {
      this.$store.dispatch('setMonthinview', ['workplaces', iDelta])
    },
    updateWorkplacesearch() {
      this.$store.dispatch('updateWorkplacesearch', [this.workplacesearch, 'workplaces']);
    },
    clearWorkplacesearch() {
      this.workplacesearch = '';
      this.$store.dispatch('updateWorkplacesearch', [this.workplacesearch, 'workplaces'])
    },
    makeReservation(tariff) {
      const id = tariff.id
      if (this.$store.state.displaytype == 'clean') {
        // Open the booker in separate tab.
        let iMonth = this.$store.state.monthinview.monthfrom;
        window.open(location.href.replace('#clean', '')+'#'+iMonth, 'loadsbooker')
      } else {
        if (this.$store.state.reservationids.indexOf(id) == -1) {
          this.$store.dispatch('makeReservation', ['workplaces', tariff.id])
        } else {
          this.$store.dispatch('removeReservation', tariff)
        }
      }
    },
    removeReservation(tariff) {
      this.$store.dispatch('removeReservation', tariff)
    },
    disableWeekbutton(month) {
      return month.tariffofmonth.find((t) => {
        return t.lBookable == 0 || this.$store.state.reservationids.indexOf(t.id) > -1
      }) !== undefined
    },
    makeReservationalldays(month) {
      month.tariffofmonth.forEach((d) => {
        this.makeReservation(d)
      });
    },
    displayCart() {
      this.$store.dispatch('displayCart')
    },
    checkForhash() {
      // Check for a location in the hash. If found filter on the space
      // If the last entry of the hash 'clean' than set displaytype to clean
      const aHash = location.hash.split("#")
      let iMonth = 0;
      if (aHash[0] == '') {
        aHash.shift()
      }
      if (aHash.length > 1) {
        if (aHash.length == 3) {
          var cDisplay = aHash.pop();
          if(cDisplay == 'clean') {
            this.$store.dispatch('setDisplaytype', cDisplay)
          }
          else {
            iMonth = cDisplay
          }
        }
        if(iMonth !== 0) {
          let iMonthfrom = iMonth
          // let iYearfrom = parseInt(iMonthfrom.toString().substring(0,4))
          // let iMonthnumfrom = iMonthfrom.toString().substring(5,6)
          //
          // if(iMonthnumfrom == '00') {
          //   iYearfrom--
          //   iMonthfrom = iYearfrom.toString() + '01'
          // }


          let iMonthto = (parseInt(iMonth) + 3).toString();
          let iYearto = parseInt(iMonthto.toString().substring(0,4))
          let iMonthnumto = iMonthfrom.toString().substring(5,6)

          if(iMonthnumto == '13') {
            iYearto++
            iMonthfrom = iYearto.toString() + '01'
          }
          else {
            iMonthfrom = iMonthfrom.toString()
          }

          if((this.$store.state.displaytype !== 'clean')) {
            this.$store.state.lastSearch.monthinview = JSON.parse(JSON.stringify(this.$store.state.monthinview));
          }
          this.$store.state.monthinview.monthfrom = iMonthfrom
          this.$store.state.monthinview.monthto = iMonthto
          this.$store.state.hasHash = true;
        }

        var cSpace = aHash.pop()
        cSpace = decodeURI(cSpace).replaceAll(' ', '').toLowerCase()
        this.$store.dispatch('setMonthinview', ['workplaces', 0, true])
        this.$store.dispatch('updateWorkplacesearch', [cSpace, 'workplaces']);

        //this.workplacesearch = this.$store.state.workplacesearch;
      } else {
        // Get the spaces
        this.$store.dispatch('setMonthinview', ['workplaces'])
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log(to, from, next)
    this.checkForhash()

    this.$parent.$forceUpdate();
  },
  mounted() {
    // Restore the searchvalue
    this.workplacesearch = this.$store.state.workplacesearch;

    this.checkForhash()
  },
}
</script>
