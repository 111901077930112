<template>
  <div class="home bg-slate-100">
    <div class="">
      <div class="w-full">
        <div class="w-full p-0 sm:p-6  border border-gray-200 bg-white"
             :class="{'hidden' : this.$store.state.displaytype == 'clean'}"
        >
          <h1 class="text-lg md:text-4xl mt-3 ml-3 md:mt-0 md:ml-0">{{ $t('BOOKINGCONDITIONS') }}</h1>
        </div>
        <div class="overflow-y-scroll  max-h-screen pb-80">
          <div class="w-full ">
            <div class="p-0 sm:p-2 bg-slate-100">
              <div class="intro m-2 p-5 bg-white border border-gray-200">
                <h2 class="text-left">{{ $t('BOOKINGCONDITIONS_TITLE') }}</h2>
                <p class="text-left" v-html="$t('BOOKINGCONDITIONS_BODY')"></p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="p-0 sm:p-2 bg-slate-100 ">
              <div class="contact m-2 p-5 bg-white border-2 border-gray-200  clear-both  h-96  rounded-md">
                <div class="contact-left w-full sm:w-1/2 float-left">
                  <h2 class="text-left">Contact</h2>
                  <ul class="text-left list-outside">
                    <li>Ms Tarnweg 10</li>
                    <li>1033 SK AMSTERDAM</li>
                    <li>hello@loads.work</li>
                    <li>kvk: 76899993</li>
                  </ul>
                </div>
                <div class="contact-left pl-8 w-full sm:w-1/2 float-left pt-6 mb-8 text-left">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.1727831941193!2d4.891099251745857!3d52.40354467969309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60978390b643b%3A0x6946f3d88ef9d8ef!2sLOADS.amsterdam!5e0!3m2!1sen!2snl!4v1663182530792!5m2!1sen!2snl"
                          width="300"
                          height="300"
                          style="border:0;"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"></iframe>
                  <div class="clear-both"></div>
                </div>
              </div>
              <div class=" clear-both"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BookingConditions',
}
</script>

<style scoped>
.contact a,
.contact a:link,
.contact a:visited,
.contact a:active {
  text-decoration: underline !important;
}

</style>
