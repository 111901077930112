import { createApp } from 'vue'
import App from './App.vue'
import store from "./store";
import router from './router'
import '@/assets/css/main.css';
import i18n from './localization'
import NProgress from 'nprogress'
import Loader from 'vue-loading-indicator'
import VueCookies from 'vue-cookies'
;

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(NProgress, {})
    .use(Loader)
    .use(VueCookies)
    .mount('#app')


