<template>
    <div class="home bg-slate-100">
        <div class="">
            <div class="w-full">
                <div class="w-full p-0 sm:px-7 sm:py-4 sm:pb-2 border border-gray-200 bg-white">
                    <h1 class="text-lg md:text-4xl mt-1 ml-3 sm:mt-0 sm:ml-0 sm:pt-2 sm:pb-4">{{ $t("HOME") }}</h1>
                </div>
                <div class="overflow-y-scroll max-h-screen pb-80 sm:pt-0">
                    <div class="w-full">
                        <div class="p-0 sm:p-4 bg-slate-100">
                            <div class="intro m-2 p-5 bg-white border border-gray-200 rounded-md">
                                <h2 class="text-left">{{ $t("HOME_WELCOME_TITLE") }}</h2>
                                <p class="text-left" v-html="$t('HOME_WELCOME_BODY')"></p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full sm:w-1/2 float-left">
                        <div class="sm:p-2 bg-slate-100">
                            <div class="intro m-2 p-5 bg-white border border-gray-200 rounded-md">
                                <h2 class="text-left">
                                    {{ $t("MEETINGSPACES") }}
                                    <span
                                        class="inline-block float-right items-center rounded-md bg-orange-100 px-2.5 py-0.5 text-sm font-medium text-orange-800"
                                        >{{ meetinspacescount }} {{ $t("SPACES") }}</span
                                    >
                                </h2>
                                <div class="meetingspaces-text text-left" v-html="$t('HOME_MEETINGSPACES_BODY')"></div>
                                <router-link
                                    :to="{ name: 'meetingspaces' }"
                                    class="button justify-center m-auto inline-flex w-full mt-5 items-center rounded-md border border-transparent bg-black px-4 py-2 text-lg font-bold font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                >
                                    <UserGroupIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                                    {{ $t("GOTO") }} {{ $t("MEETINGSPACES") }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="md:w-1/2 float-left">
                        <div class="p-0 sm:p-2 bg-slate-100">
                            <div class="intro workspaces m-2 p-5 bg-white border border-gray-200 rounded-md">
                                <h2 class="text-left">
                                    {{ $t("WORKPLACES") }}
                                    <span
                                        class="inline-block float-right items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800"
                                        >{{ workplacescount }} {{ $t("SPACES") }}</span
                                    >
                                </h2>
                                <div class="workspaces-text text-left" v-html="$t('HOME_WORKPLACES_BODY')"></div>
                                <router-link
                                    :to="{ name: 'workplaces' }"
                                    class="button justify-center m-auto inline-flex w-full mt-5 items-center rounded-md border border-transparent bg-black px-4 py-2 text-lg font-bold font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                >
                                    <ComputerDesktopIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                                    {{ $t("GOTO") }} {{ $t("WORKPLACES") }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="p-0 sm:p-2 bg-slate-100">
                            <div class="contact m-2 p-5 bg-white border-2 border-gray-200 clear-both h-192 rounded-md">
                                <div class="contact-left w-full sm:w-1/2 float-left">
                                    <h2 class="text-left">Contact</h2>
                                    <ul class="text-left list-outside">
                                        <li><b>Loads HQ</b></li>
                                        <li><b>Coworking Club 'FRIENDS'</b></li>
                                        <li>Ms Tarnweg 10</li>
                                        <li>1033 SK AMSTERDAM</li>
                                        <li>hello@loads.amsterdam</li>
                                        <li>kvk: 88573559</li>
                                    </ul>
                                </div>
                                <div class="contact-left sm:pl-0 w-full sm:w-1/2 float-left pt-6 mb-8 text-left">
                                    <div style="width: 100%; overflow: hidden; height: 150px;">
                                        <iframe
                                            src="https://www.google.com/maps/d/embed?mid=1ICdh3CLHdnFcvvFLcABbudhXx61_y2Q&hl=nl&ehbc=2E312F"
                                            width="100%"
                                            height="600"
                                            frameborder="0"
                                            style="border: 0; margin-top: -150px;"
                                        >
                                        </iframe>
                                    </div>
                                    <div class="clear-both"></div>
                                </div>
                                <div class="pb-8">
                                    <div class="contact-left w-full sm:w-1/2 float-left">
                                        <ul class="text-left list-outside">
                                            <li><b>Coworking Club ‘TANKER’</b></li>
                                            <li>TT Melaniaweg 2</li>
                                            <li>1033 ST</li>
                                            <li>AMSTERDAM</li>
                                            <li>hello@loads.amsterdam</li>
                                            <li>kvk: 88573559</li>
                                        </ul>
                                    </div>
                                    <div class="contact-left w-full sm:w-1/2 float-left">
                                        <ul class="text-left list-outside">
                                            <li><b>Startup Club ‘Witbol’</b></li>
                                            <li>Witbolstraat 3</li>
                                            <li>1032 LC AMSTERDAM</li>
                                            <li>hello@loads.amsterdam</li>
                                            <li>kvk: 88573559</li>
                                            <li>&nbsp;</li>
                                            <li>&nbsp;</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="pb-4">
                                    <div class="contact-left w-full sm:w-1/2 float-left">
                                        <ul class="text-left list-outside">
                                            <li><b>Ateliers ‘BLOKNOORD’</b></li>
                                            <li>TT Melaniaweg 7</li>
                                            <li>1033 ST AMSTERDAM></li>
                                            <li>hello@loads.amsterdam></li>
                                            <li>kvk: 88573559></li>
                                        </ul>
                                    </div>
                                    <div class="contact-left w-full sm:w-1/2 float-left">
                                        <ul class="text-left list-outside">
                                            <li><b>MACA Moving Arts Centre Amsterdam</b></li>
                                            <li>Ms van Riemsijkweg 61-63</li>
                                            <li>1033 RC AMSTERDAM</li>
                                            <li>maca@loads.amsterdam</li>
                                            <li>kvk: 72374500</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="clear-both"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import {
    ComputerDesktopIcon,
    UserGroupIcon,
    // CubeTransparentIcon
} from "@heroicons/vue/24/solid";

export default {
    name: "HomeView",
    components: {
        ComputerDesktopIcon,
        UserGroupIcon,
        // CubeTransparentIcon
    },
    computed: {
        workplacescount() {
            return this.$store.state.workplacecount;
        },
        meetinspacescount() {
            return this.$store.state.meetingspacecount;
        },
    },
    mounted() {
        this.$store.dispatch("getHome", "home");
    },
};
</script>

<style scoped>
.contact a,
.contact a:link,
.contact a:visited,
.contact a:active {
    text-decoration: underline !important;
}
</style>
