<template>
    <div
        class="float-left w-full lg:block lg:w-2/5 sticky bg-slate-100"
        :class="{
            hidden: !displayCarton,
            'top-16': this.$store.state.displaytype !== 'clean',
            'top-2': this.$store.state.displaytype == 'clean',
        }"
    >
        <div class="p-2 sm:p-4 sm:mb-5 sm:pb-5">
            <div class="intro m-2 p-0 sm:p-0" :class="{ hidden: this.$store.state.displaytype == 'clean' }">
                <h1 class="text-lg md:text-4xl mt-3 ml-3 md:mt-0 md:ml-0 float-left">{{ $t("MY_RESERVATION") }}</h1>
                <button
                    type="button"
                    class="float-right lg:hidden w-20 h-10 mt-1 inline-flex justify-center items-center rounded-full border-none border-white px-3 py-2 text-sm font-medium leading-4 text-black hover:bg-gray-50 focus:outline-none"
                    @click="
                        displayCart();
                        dismiss('bookingmessage');
                    "
                >
                    <svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 19l-7-7m0 0l7-7m-7 7h18"
                        ></path>
                    </svg>
                    {{ $t("BACK") }}
                </button>
            </div>
            <div
                class="w-full float-left max-h-screen overflow-y-scroll pb-44"
                :class="{ 'reservation-header': this.$store.state.displaytype !== 'clean' }"
            >
                <div class="m-0 sm:mt-2 mr-3 p-0 bg-white border border-gray-200 rounded-md">
                    <div class="p-0" v-if="cBookingmessage == ''">
                        <div
                            class="intro workspaces m-0 p-0 sm:p-2 sm:pt-0"
                            v-if="myReservations.length == 0 && myBookedextraproducts.length == 0"
                        >
                            <div class="border-dashed border-2 rounded-md p-6 m-6">
                                <div class="w-full text-lg font-bold text-gray-300">
                                    {{ $t("NO_RESERVATIONS_YET_TITLE") }}
                                </div>
                                <div class="w-full text-base text-gray-300">{{ $t("NO_RESERVATIONS_YET") }}</div>
                            </div>
                        </div>
                        <div class="intro workspaces m-0 p-0 border-b border-gray-200" v-if="myReservations.length > 0">
                            <div
                                v-for="reservation in myReservations"
                                :key="reservation.id"
                                :reservation="reservation"
                                class="w-full sm:p-5 p-1 pt-6 border-b border-gray-200"
                            >
                                <div class="grid grid-cols-6 gap-1">
                                    <div class="hidden md:inline-block col-span-2 float-left">
                                        <button
                                            type="button"
                                            class="w-6 h-6 relative -top-3 float-left z-50"
                                            @click="removeReservation(reservation)"
                                        >
                                            <XCircleIcon class="h-6 w-6 fill-black stroke-white" aria-hidden="true" />
                                        </button>
                                        <img
                                            class="float-left relative -left-3 w-16 md:w-24 rounded-md z-0"
                                            :src="
                                                this.$store.state.cUrlplanner +
                                                '/images/spaces/' +
                                                reservation.cSpaceimage
                                            "
                                            alt=""
                                        />
                                    </div>
                                    <div class="col-span-6 md:col-span-4 grid grid-cols-3 md:grid-cols-2">
                                        <div class="col-span-1 md:hidden">
                                            <button
                                                type="button"
                                                class="w-6 h-6 relative -top-3 float-left z-50"
                                                @click="removeReservation(reservation)"
                                            >
                                                <svg
                                                    class="w-6 h-6"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </button>
                                            <img
                                                class="float-left relative -left-3 w-16 md:w-32 rounded-md z-0"
                                                :src="
                                                    this.$store.state.cUrlplanner +
                                                    '/images/spaces/' +
                                                    reservation.cSpaceimage
                                                "
                                                alt=""
                                            />
                                        </div>
                                        <div class="col-span-1">
                                            <div>
                                                <h2 class="text-base md:text-xl">{{ reservation.cSpace }}</h2>
                                                <!--                        <span class="inline-block float-left items-center rounded-md bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">{{ reservation.cLocation }}</span>-->
                                            </div>
                                        </div>
                                        <div class="col-span-1">
                                            <div>
                                                <h2
                                                    class="w-full text-right text-base md:text-xl"
                                                    :class="{ 'line-through': parseFloat(reservation.dDiscount) > 0 }"
                                                >
                                                    &euro;{{ reservation.dTariff.replace(".", ",") }}
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="col-span-2" v-if="reservation.dDiscount > 0">
                                            <div>
                                                <p
                                                    class="w-full text-right text-base md:text-lg"
                                                    :class="{ hidden: parseFloat(this.$store.state.discount) == 0 }"
                                                >
                                                    <span
                                                        >{{ $t("YOUGETADISCOUNT") }}:
                                                        {{ parseFloat(this.$store.state.discount).toFixed(0) }}%</span
                                                    >
                                                </p>
                                                <h2
                                                    class="w-full text-right text-base md:text-xl text-green-700"
                                                    :class="{ hidden: parseFloat(this.$store.state.discount) == 0 }"
                                                >
                                                    <span>{{ $t("YOURPRICE") }}: </span>
                                                    &euro;{{ reservation.dDiscountedtariff }}
                                                </h2>
                                                <span
                                                    class="inline-block float-right items-center rounded-md bg-blue-200 px-2.5 py-0.5 text-xs font-medium text-blue-800"
                                                    >{{ reservation.cSpacetype }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-span-3 md:col-span-2 my-2 pl-4 sm:pl-0">
                                            <div class="text-left text-sm font-bold">
                                                {{ reservation.cSentence }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-6 md:col-span-6 my-2">
                                        <div
                                            v-for="product in reservation.products"
                                            :key="product.id"
                                            :product="product"
                                            class="w-full border border-gray-200 rounded-md grid grid-cols-4 p-4 my-4"
                                        >
                                            <div class="col-span-2 font-bold text-left text-sm font-bold">
                                                {{ product.cDescription }}
                                            </div>
                                            <div class="col-span-1">
                                                <button
                                                    class="w-5 h-5"
                                                    @click="changeQuantity(product, -1)"
                                                    :disabled="product.iQuantity < 1"
                                                    :class="{ 'cursor-not-allowed': product.iQuantity < 1 }"
                                                >
                                                    <svg
                                                        class="w-5 h-5 p-0.5 bg-gray-200 stroke-gray-700 rounded-full"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M20 12H4"
                                                        ></path>
                                                    </svg>
                                                </button>
                                                <div class="inline-block w-8 text-center align-top">
                                                    {{ product.iQuantity }}
                                                </div>
                                                <button class="w-5 h-5" @click="changeQuantity(product, 1)">
                                                    <svg
                                                        class="w-5 h-5 p-0.5 bg-gray-200 stroke-gray-700 rounded-full"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div class="col-span-1 text-right">
                                                &euro;{{ product.dPrice.replace(".", ",") }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-span-6"
                                        v-if="reservation.bookingerror && reservation.bookingerror !== ''"
                                    >
                                        <span
                                            class="w-full inline-block items-center rounded-md bg-pink-100 px-2.5 py-0.5 text-xl text-center font-bold text-pink-800"
                                            >{{ $t(reservation.bookingerror) }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="intro workspaces p-2 border-b border-gray-200 lg:mb-2"
                            v-if="myBookedextraproducts.length > 0"
                        >
                            <div class="m-0 p-0 lg:mb-2 lg:p-2 border border-gray-200">
                                <h2>{{ $t("EXTRAPRODUCTS") }}</h2>
                                <div
                                    v-for="bookedextraproduct in myBookedextraproducts"
                                    :key="bookedextraproduct.id"
                                    :bookedextraproduct="bookedextraproduct"
                                    class="w-full rounded-md grid grid-cols-4 p-4 my-4"
                                >
                                    <div class="col-span-2 font-bold text-left">
                                        {{ bookedextraproduct.cDescription }}
                                    </div>
                                    <div class="col-span-1">
                                        <button
                                            class="w-5 h-5"
                                            @click="changeQuantity(bookedextraproduct, -1, true)"
                                            :disabled="bookedextraproduct.iQuantity < 1"
                                            :class="{ 'cursor-not-allowed': bookedextraproduct.iQuantity < 1 }"
                                        >
                                            <svg
                                                class="w-5 h-5 p-0.5 bg-gray-200 stroke-gray-700 rounded-full"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M20 12H4"
                                                ></path>
                                            </svg>
                                        </button>
                                        <div class="inline-block w-8 text-center align-top">
                                            {{ bookedextraproduct.iQuantity }}
                                        </div>
                                        <button class="w-5 h-5" @click="changeQuantity(bookedextraproduct, 1, true)">
                                            <svg
                                                class="w-5 h-5 p-0.5 bg-gray-200 stroke-gray-700 rounded-full"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="col-span-1 text-right">
                                        &euro;{{ bookedextraproduct.dPrice.replace(".", ",") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="myReservations.length !== 0 || myBookedextraproducts.length !== 0"
                            class="intro workspaces py-2 lg:mt-2"
                        >
                            <div class="grid grid-cols-2">
                                <div class="col-span-2 p-4">
                                    <!--                  <div class="float-left font-bold text-base md:text-2xl">{{ $t('MYDATA') }}</div>-->
                                    <div
                                        class="overflow-hidden rounded-md border border-gray-200 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
                                    >
                                        <textarea
                                            rows="3"
                                            name="remarks"
                                            id="remarks"
                                            class="block w-full resize-none border-0 py-3 text-base md:text-lg text-gray-700"
                                            v-model="cRemarks"
                                            :placeholder="$t('MYRESERVATIONREMARKS')"
                                            @change="changeRemarks()"
                                        ></textarea>
									</div>
                                    <div
                                        class="overflow-hidden rounded-md border border-gray-200 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
                                    >
                                        <input
                                            type="reference"
                                            name="reference"
                                            id="reference"
                                            v-model="cReference"
                                            class="w-full border-gray-200 float-left border text-base md:text-lg rounded-md h-9 p-2 focus:outline-none"
                                            :placeholder="$t('MYRESERVATIONREFERENCE')"
                                            @keyup="changeReference()"
                                            @paste="changeReference()"
                                        />
                                    </div>
                                </div>
                                <div class="col-span-2 p-4">
                                    <label
                                        for="email"
                                        class="text-left block text-base md:text-xl font-medium text-gray-700"
                                        >{{ $t("EMAIL") }} <span class="text-red-500">*</span></label
                                    >
                                    <div class="mt-1">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            v-model="cEmail"
                                            class="w-full border-gray-200 float-left border text-base md:text-lg rounded-md h-9 p-2 focus:outline-none"
                                            placeholder="hello@loads.work"
                                            @keyup="changeEmail()"
                                            @paste="changeEmail()"
                                        />
                                    </div>
                                </div>
                                <div v-if="lBookernameneeded" class="col-span-2 p-4">
                                    <div class="pt-3">
                                        <label
                                            for="bookername"
                                            class="text-base md:text-xl text-left block font-medium text-gray-700"
                                            >{{ $t("BOOKERNAME") }} <span class="text-red-500">*</span></label
                                        >
                                        <input
                                            type="text"
                                            name="bookername"
                                            id="email"
                                            v-model="cBookername"
                                            class="w-full border-gray-200 float-left border text-lg rounded-md h-9 p-2 focus:outline-none"
                                            placeholder=""
                                            @keyup="changeBookername()"
                                        />
                                    </div>
                                    <div class="mt-1">
                                        <label
                                            for="bookercompany"
                                            class="text-base md:text-xl text-left block font-medium text-gray-700"
                                            >{{ $t("BOOKERCOMPANY") }} <span class="text-red-500">*</span></label
                                        >
                                        <input
                                            type="text"
                                            name="bookercompany"
                                            id="bookercompany"
                                            v-model="cBookercompany"
                                            class="w-full border-gray-200 float-left border text-lg rounded-md h-9 p-2 focus:outline-none"
                                            placeholder=""
                                            @keyup="changeBookercompany()"
                                        />
                                    </div>
                                    <div class="mt-1">
                                        <label
                                            for="bookercompanyaddress"
                                            class="text-base md:text-xl text-left block font-medium text-gray-700"
                                            >{{ $t("BOOKERCOMPANYADDRESS") }} </label
                                        >
                                        <input
                                            type="text"
                                            name="bookercompany"
                                            id="bookercompany"
                                            v-model="cBookercompanyaddress"
                                            class="w-full border-gray-200 float-left border text-lg rounded-md h-9 p-2 focus:outline-none"
                                            placeholder=""
                                            @keyup="changeBookercompanyaddress()"
                                        />
                                    </div>
                                    <div class="mt-1 grid grid-cols-3 gap-2 w-full">
                                        <div class="col-span-1">
                                            <label
                                                for="bookercompanypostalcode"
                                                class="text-base md:text-xl text-left block font-medium text-gray-700"
                                                >{{ $t("BOOKERCOMPANYPOSTALCODE") }}
                                            </label>
                                        </div>
                                        <div class="col-span-2">
                                            <label
                                                for="bookercompanycity"
                                                class="text-base md:text-xl text-left block font-medium text-gray-700"
                                                >{{ $t("BOOKERCOMPANYCITY") }}                                                
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="bookercompanypostalcode"
                                                id="bookercompanypostalcode"
                                                v-model="cBookercompanypostalcode"
                                                class="w-full border-gray-200 float-left border text-lg rounded-md h-9 p-2 focus:outline-none"
                                                placeholder=""
                                                @keyup="changeBookercompanypostalcode()"
                                            />
                                        </div>
                                        <div class="col-span-2">
                                            <input
                                                type="text"
                                                name="bookercompanycity"
                                                id="bookercompanycity"
                                                v-model="cBookercompanycity"
                                                class="w-full border-gray-200 float-left border text-lg rounded-md h-9 p-2 focus:outline-none"
                                                placeholder=""
                                                @keyup="changeBookercompanycity()"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-2 sm:p-4 border-b border-gray-200 my-3 p-3">
                                    <input
                                        id="agreetoterms"
                                        name="agreetoterms"
                                        type="checkbox"
                                        v-model="lAgreetoterms"
                                        @change="changeAgreetoterms(lAgreetoterms)"
                                        class="h-4 w-4 float-left rounded border-gray-300 ml-1 mt-1 text-blue-600 focus:ring-blue-500"
                                    />
                                    <!--                  <div class="ml-3 text-sm">-->
                                    <label
                                        for="agreetoterms"
                                        class="font-medium text-base md:text-lg text-gray-700 float-left ml-2 relative left-1 md:left-0 md:bottom-0"
                                    >
                                        {{ $t("AGREEMENT") }}
                                        <router-link :to="{ name: 'bookingconditions' }" class="underline">
                                            {{ $t("BOOKINGTERMS") }}
                                        </router-link>
                                        <span class="text-red-500">*</span>
                                    </label>

                                    <!--                  </div>-->
                                </div>
                                <div class="col-span-1 pb-0 pl-4">
                                    <div class="float-left font-extrabold text-lg md:text-3xl">
                                        {{ $t("TOTALAMOUNT") }}
                                    </div>
                                </div>
                                <div class="col-span-1 pb-0 pr-4">
                                    <div class="float-right font-extrabold text-lg md:text-3xl">
                                        &euro; {{ dTotalamount.replace(".", ",") }}
                                    </div>
                                </div>
                                <div class="col-span-2 pb-4 pr-4">
                                    <div class="float-right font-bold text-xs md:text-lg">{{ $t("EXCLVAT") }}</div>
                                </div>
                                <div class="col-span-2 p-4">
                                    <button
                                        type="button"
                                        class="justify-center button m-auto inline-flex w-full mt-5 items-center rounded-md border border-transparent bg-black xl:py-5 py-2 text-lg md:text-2xl font-bold font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                        :disabled="!lBookingbuttonenabled"
                                        :class="{ 'disabled:opacity-50 cursor-not-allowed': !lBookingbuttonenabled }"
                                        @click.once="doBooking()"
                                    >
                                        <FireIcon class="-ml-1 mr-3 h-6 w-6" aria-hidden="true" />
                                        {{ $t("BOOK_NOW") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rounded-md bg-green-50 p-4" v-if="cBookingmessage !== ''">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div class="ml-3">
                                <p class="text-xl font-medium text-green-800">
                                    {{ $t(cBookingmessage) }}
                                </p>
                            </div>
                            <div class="ml-auto pl-3">
                                <div class="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                        @click="dismiss('bookingmessage')"
                                    >
                                        <span class="sr-only">Dismiss</span>
                                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <router-link
                    :to="{ name: 'workplaces' }"
                    class="button sm:hidden justify-center m-auto inline-flex w-full mt-5 items-center rounded-md border border-transparent bg-black px-4 py-2 text-lg font-bold font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    v-if="cBookingmessage !== '' || myReservationids.length == 0"
                    @click="
                        dismiss('bookingmessage');
                        displayCart();
                    "
                >
                    <ComputerDesktopIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                    {{ $t("GOTO") }} {{ $t("WORKPLACES") }}
                </router-link>
                <router-link
                    :to="{ name: 'meetingspaces' }"
                    class="button sm:hidden justify-center m-auto inline-flex w-full mt-5 items-center rounded-md border border-transparent bg-black px-4 py-2 text-lg font-bold font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    v-if="cBookingmessage !== '' || myReservationids.length == 0"
                    @click="
                        dismiss('bookingmessage');
                        displayCart();
                    "
                >
                    <UserGroupIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                    {{ $t("GOTO") }} {{ $t("MEETINGSPACES") }}
                </router-link>
            </div>
        </div>
    </div>
    <div class="clear-both"></div>
</template>

<script>
import { FireIcon, CheckCircleIcon, XMarkIcon, XCircleIcon } from "@heroicons/vue/24/solid";

import { useVuelidate } from "@vuelidate/core";

import { required, email } from "@vuelidate/validators";

export default {
    name: "MyReservations",
    components: {
        FireIcon,
        CheckCircleIcon,
        XMarkIcon,
        XCircleIcon,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            lAgreetoterms: "",
            cEmail: this.$store.state.email,
            lEmailvalid: false,
            cBookername: "",
            cBookercompany: "",
            cBookercompanyaddress: "",
            cBookercompanypostalcode: "",
            cBookercompanycity: "",
            cRemarks: "",
        };
    },
    validations() {
        return {
            cEmail: {
                required,
                email,
            },
        };
    },
    computed: {
        myReservations() {
            const aReservations = this.$store.state.reservations;
            aReservations.forEach((r) => {
                var cDay = this.$store.state.weekdays[r.iWeekday];
                var cMonth = this.$store.state.months[parseInt(r.iMonth.substring(4, 6))];
                var iYear = r.iMonth.substring(0, 4);
                var cSentence = "";
                switch (r.cTarifftype) {
                    case "TEAMFLEX":
                        cSentence = this.$i18n.t("ALLMONTHYEAR");
                        cSentence = cSentence.replace("~month~", this.$i18n.t(cMonth));
                        cSentence = cSentence.replace("~year~", iYear);
                        r.cSentence = cSentence;
                        break;
                    case "DAY_PART":
                        cSentence =
                            this.$i18n.t(cDay) +
                            " " +
                            this.$i18n.t(r.cDaypart).toLowerCase() +
                            " " +
                            r.iMonthday +
                            " " +
                            this.$i18n.t(cMonth) +
                            " " +
                            iYear;
                        r.cSentence = cSentence;
                        break;
                    default:
                        cSentence = this.$i18n.t("ALLDAYSINMONTHYEAR");
                        cSentence = cSentence.replace("~weekdays~", this.$i18n.t(cDay + "S"));
                        cSentence = cSentence.replace("~month~", this.$i18n.t(cMonth));
                        cSentence = cSentence.replace("~year~", iYear);
                        r.cSentence = cSentence;
                        break;
                }
            });
            this.changeEmail();
            return aReservations;
        },
        myReservationids() {
            return this.$store.state.reservationids;
        },
        myBookedextraproducts() {
            return this.$store.state.bookedextraproducts;
        },
        dTotalamount() {
            return this.$store.state.totalamount;
        },
        lBookernameneeded() {
            return this.$store.state.bookernameneeded;
        },
        lBookingbuttonenabled() {
            if (this.lAgreetoterms && this.lEmailvalid) {
                if (this.lBookernameneeded && (this.cBookername == "" || this.cBookercompany == "")) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        cBookingmessage() {
            return this.$store.state.bookingmessage;
        },
        displayCarton() {
            return this.$store.state.displaycart;
        },
        displaytype() {
            return this.$store.state.displaytype;
        },
    },
    methods: {
        removeReservation(reservation) {
            this.$store.dispatch("removeReservation", [reservation]);
        },
        changeQuantity(product, delta, lDeleteonzero = false) {
            product.iQuantity = product.iQuantity + delta;
            if (lDeleteonzero == true && product.iQuantity == 0) {
                this.$store.dispatch("removeBookedextraproduct", product);
            }
            this.$store.dispatch("setTotalamount");
        },
        changeAgreetoterms(lAgreetoterms) {
            this.$store.dispatch("setAgreetoterms", lAgreetoterms);
        },
        changeBookername() {
            this.$store.dispatch("setBookername", this.cBookername);
        },
        changeBookercompany() {
            this.$store.dispatch("setBookercompany", this.cBookercompany);
        },
        changeBookercompanyaddress() {
            this.$store.dispatch("setBookercompanyaddress", this.cBookercompanyaddress);
        },
        changeBookercompanypostalcode() {
            this.$store.dispatch("setBookercompanypostalcode", this.cBookercompanypostalcode);
        },
        changeBookercompanycity() {
            this.$store.dispatch("setBookercompanycity", this.cBookercompanycity);
        },
        changeEmail() {
            this.v$.cEmail.$validate().then((lValid) => {
                this.lEmailvalid = lValid;
                if (lValid) {
                    setTimeout(() => {
                        this.$store.dispatch("setEmail", this.cEmail).then(() => {
                            setTimeout(() => {
                                this.$store.dispatch("setTotalamount");
                            }, 500);
                        });
                    }, 500);
                }
            });
        },
        changeRemarks() {
            this.$store.dispatch("setRemarks", this.cRemarks);
        },
        changeReference() {
            this.$store.dispatch("setReference", this.cReference);
        },
        doBooking() {
            this.$store.dispatch("doBooking");
        },
        dismiss(cMessagetype) {
            this.$store.state[cMessagetype] = "";
        },
        displayCart() {
            if (this.$store.state.email !== "") {
                this.cEmail = this.$store.state.email; // eslint-disable-line no-undef
            }
            this.$store.dispatch("displayCart");
        },
    },
    create() {
        // data.cEmail = 'sjors'
    },
};
</script>

<style scoped></style>
